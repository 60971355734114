import {organisers} from "./d_organisers_Y3";
import React from "react";
let mainRoute = "/application/channel/Y3";

const channels = {
  Y3Session1:{
    heading: "Session 1",
    title:"Awakening our Human Possibilities - A Recap",
    cost: 250,
    excerpt:<div>Students explore the importance of awakening our human possibilities - from the larger ‘awakened citizen’ point of view, and the need for awakening our six universal possibilities in the context of the society (Indian society) we live in. <br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon: "./images/assets/session-icons/year3-session1-icon.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_001,
    poster: null,
    teaser: null,
    offering: null
  },Y3Session2:{
    heading:"Session 2",
    title: "Developing faith in our infinite possibilities within",
    cost: 450,
    excerpt: <div>Students engage with 3 critical mental blocks or barriers, that can prevent the awakening of our possibilities, and learn 3 key ‘ways to think’ or thinking approaches, which will help remove these blocks and open up the way to unleashing all of our inner possibilities.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session2-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_002,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session3:{
    heading:"Session 3",
    title: "Awakening our possibilities in the context of the classroom",
    cost: 450,
    excerpt: <div>Students recognize how our class work can be an opportunity to awaken our possibilities and that school life need not be an endless stream of experiences and activities, but can be a series of ‘products to be made’, ‘commitments to be kept’, ‘challenges to be overcome’ and ‘aspirations to be realized’.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session3-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_003,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session4:{
    heading:"Session 4",
    title: "Awakening our possibilities in the context of play and with friends",
    cost: 450,
    excerpt: <div>Students learn different approaches that lead to an awakening of their possibilities during play and while with friends and peers.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session4-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_004,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session5:{
    heading:"Session 5",
    title: "Awakening our possibilities in the context of family",
    cost: 450,
    excerpt: <div>Enabling students to recognize some of the critical ‘success factors’ in family life (for example, learning to accept and adjust with each other, living as a responsible member of a small collective, the importance of demonstrating human concern in relationships, etc.) and seeing each of these as spaces for awakening possibilities.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session5-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_005,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session6:{
    heading:"Session 6",
    title: "Awakening our possibilities in the context of the community we live in",
    cost: 450,
    excerpt: <div>Students learn different approaches that lead to an awakening of their possibilities in situations that come up in relation to the community they live in.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session6-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_006,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session7:{
    heading:"Session 7",
    title: "Awakening our possibilities in the context of the nation",
    cost: 450,
    excerpt: <div>In this session, students learn approaches in the context of citizenship and sharing in the responsibility of our nation, and thereby awaken their possibilities.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session7-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_007,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session8:{
    heading:"Session 8",
    title: "Awakening our possibilities in our career",
    cost: 450,
    excerpt: <div>Students discover how different possibilities can get awakened in different careers they choose. They also learn an important new criteria for making career choices – “How will the career give me the opportunity to bring out my hidden potential and inner possibilities as a human being?”<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session8-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_008,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session9:{
    heading:"Session 9",
    title: "Introduction to the 'Pathways to Awakening'",
    cost: 250,
    excerpt: <div>Students are introduced to two modes that awaken their possibilities (1) by using every situation or context they are in as an opportunity to awaken possibilities (as seen in session 3-8) (2) by practice and consciously developing possibilities through the 6 pathways to awakening that will be shown in the next 6 sessions (10-15).<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session9-icon.jpg",
    icon_bg: '#87D6A7',
    organisers: organisers.os_009,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session10:{
    heading:"Session 10",
    title: "Pathway 1 - Discrimination (Viveka)",
    excerpt: <div>Students explore the pathway of discrimination (viveka), that gives us the power to make right choices, thereby leading to an awakening of our possibilities.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session10-icon.jpg",
    icon_bg: '#F4DF84',
    organisers: organisers.os_010,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y3Session11:{
    heading:"Session 11",
    title: "Pathway 2 - Service",
    excerpt: <div>Students explore the second pathway of awakening - Service. They explore ‘service’ not in terms of a generic idea where we ‘do good’ or ‘help’ others. They appreciate how ‘service’ is a specific mode of mind or thinking that they can systematically develop in themselves.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session11-icon.jpg",
    icon_bg: '#FFCE94',
    organisers: organisers.os_011,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y3Session12:{
    heading:"Session 12",
    title: "Pathway 3 - Concentration & Focus",
    excerpt: <div>Students explore the 3rd pathway to awaken our possibilities - Concentration and focus. ‘Concentration’ is not seen as a skill or ability here, but as the mental practice of taking up one idea/ activity/ goal/ purpose and organizing all our energies around it.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session12-icon.jpg",
    icon_bg: '#EDCCFF',
    organisers: organisers.os_012,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y3Session13:{
    heading:"Session 13",
    title: "Pathway 4 - Same-Sightedness",
    excerpt: <div>Students explore how to practice same-sightedness by perceiving the deeper dimensions on which we are equal as human beings and acting out of it – irrespective of superficial hierarchies in position, status, gender, race, color, caste, education, capability, etc.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session13-icon.jpg",
    icon_bg: '#E2DF7D',
    organisers: organisers.os_013,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y3Session14:{
    heading:"Session 14",
    title: " Pathway 5 - Positive Engagement",
    excerpt: <div>Students explore the pathway of ‘positive engagement’ that leads to openmindedness and removes a key barrier (i.e. our own fixed ideas/ prejudices/ mental blocks) that could prevent our possibilities from unfolding. They learn how to practice this in four key areas.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session14-icon.jpg",
    icon_bg: '#B4F7D1',
    organisers: organisers.os_014,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y3Session15:{
    heading:"Session 15",
    title: "Pathway 6 - Developing towards our ideal",
    excerpt: <div>Students learn how to systematically develop towards an ideal. This is one more pathway that leads to awakening of their possibilities.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session15-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_015,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y3Session16:{
    heading:"Session 16",
    title: "Program Conclusion",
    excerpt: <div>Students reflect on 6 ‘key learnings’ gained over the past three years.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year3-session16-icon.jpg",
    icon_bg: '#FFCACA',
    organisers: organisers.os_016,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  }
};

Object.keys(channels).forEach((key, i)=>{
  channels[key].route = mainRoute+"/"+key;
});

export default channels;