const INITIAL_STATE = {
  activeComponents: {},
  activeSection:0,
  hindi: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case "Navigation/NAVIGATE":
      return state;
    case "ACTIVATE_COMPONENT":
      let { courseId, target, value } = action.payload;
      return {
        ...state, activeComponents: {
          ...state.activeComponents, [courseId]: {
            ...state.activeComponents[courseId], [target]: value
          }
        }
      };
    case "ACTIVATE_SECTION":
      return { ...state, activeSection: action.payload }
    case "TOGGLE_LANGUAGE":
      return {
        ...state,
        hindi: !state.hindi
      }
    default:
      return state;
  }
};
