import firebase from "firebase/app";
import "firebase/database";
import "firebase/auth";
//import store from "../../../Redux_Store";
//let server = "http://localhost:5001/possibilities-parenting/us-central1/serverResponse";
let server = "https://us-central1-possibilities-parenting.cloudfunctions.net/serverResponse";

if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyCAIaTCYR0InKoRO3q4NiACcYOlC0a1w1Q",
    authDomain: "acp-illumine.firebaseapp.com",
    databaseURL: "https://acp-illumine.firebaseio.com",
    projectId: "acp-illumine",
    storageBucket: "acp-illumine.appspot.com",
    messagingSenderId: "715521075500",
    appId: "1:715521075500:web:886098fdfa0274638e6489",
    measurementId: "G-QC0K6DNW24"
  };
  firebase.initializeApp(config);
  firebase.analytics();
}
let database = firebase.database();

export const subscribeAProduct = function (productId, _user, amount) {
  let user = firebase
    .auth()
    .currentUser;
  if (user) {
    let uid = user.uid;
    /**REVISED LOGIC INTEGRATING THE PAYU PAYMENT */
    let txnid = database
      .ref()
      .child(`paymentHistories/`)
      .push()
      .key;
    //TODO this needs to be variablaized
    var paymentData = {
      key: "vXGyByvR",
      productinfo: productId,
      txnid,
      amount: amount,
      email: user.email,
      phone: `${_user.phoneNumber}` || "8369903919",
      lastname: _user.lastname || "",
      firstname: _user.firstName || "Not Set",
      surl: `https://awaken.illumine.in/application/paymentResponse/${txnid}`,
      furl: "https://awaken.illumine.in/application/library",
      udf1: uid
    };

    fetch(`${server}/pay`, {
      method: 'POST',
      body: JSON.stringify(paymentData)
    }).catch(err => {
      console.log(err)
    }).then(response => {
      if (!response) {
        console.log("Server is not working");
      } else {
        response
          .json()
          .then(response => {
            paymentData.hash = response;
            window
              .bolt
              .launch(paymentData, {
                responseHandler: function (BOLT) {
                  if (BOLT.response.txnStatus === "SUCCESS") {
                    fetch(`${server}/paymentResponse`, {
                      method: 'POST',
                      body: JSON.stringify(BOLT.response)
                    }).catch(err => {
                      console.log(err)
                    }).then(response_ => {
                      console.log(response_)
                    });
                  }
                },
                catchException: function (BOLT) {
                  console.log(BOLT);
                }
              });
          });
      }
    });
  } else {
    console.log("user is not signed in. Redirect to home");
  }
}