import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { bindActionCreators } from "redux";

import "./applicationDashboard.css";

import Capsule from "../../../components/MyCapsules";
// import channelData from "../../../modals/d_channels";
import channelData_Y1 from "../../../modals/d_channels_Y1";
import channelData_Y2 from "../../../modals/d_channels_Y2";
import channelData_Y3 from "../../../modals/d_channels_Y3";
import channelData_fac from "../../../modals/d_channels_fac";
import Header from "../../../components/Header";
import Footer from "../../../components/Footer";

import FirstTimeUserMsg from './firstTimeUserMsg';

import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';

import { activateSection } from "../channelDashboard/redux_actions"

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <span
      component="div"
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && <div p={3}>{children}</div>}
    </span>
  );
}

let applicationDashboard = function applicationDashboard(props) {
  let { user } = props.auth.user;
  let mySubscriptions = props.userState.mySubscriptions;
  let value = props.channelState.activeSection;
  const handleChange = (event, newValue) => {
    props.activateSection(newValue);
  };
  let isSubscribed = Object.keys(mySubscriptions).length;
  return <div className="channelDashboardCntr" style={{
    width: '100%'
  }}>
    <Header active='myCapsule' />
    <div className="channelsCntr">
      {isSubscribed
        ? <div style={{ overflow: "auto" }}>
          <div style={{ backgroundColor: "#F7F7F7", marginBottom: 65}}>
          <div style={{ display: "flex", paddingTop: 56, paddingBottom: 36,
            justifyContent: "space-between", maxWidth: 1005, margin: "auto"
          }}>
            <div>
              <div className="ch-sub-title" style={{ paddingBottom: 8 }}>Course</div>
              <div style={{ fontSize: 30, width: 400, fontWeight: 500, color: "#1D1D1D" }}>Awakened Citizen Program - Digital Version</div>
            </div>
            <div>
              <div className="ch-sub-title" style={{ paddingBottom: 8 }}>Hosts:</div>
              <div style={{ display: "flex" }}>
                <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", padding: "0px 12px" }}>
                  <div><img alt="rkm" src="/images/assets/RKM.png" height={56} /></div>
                  <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Delivered by Ramkrishna Mission,Delhi</div>
                </div>
                <div style={{ display: "flex", borderLeft: "1px solid #CFCFCF", padding: "0px 12px" }}>
                  <div><img alt="illumine-logo" src="https://s3.ap-south-1.amazonaws.com/illumine-logos/illuminelogo.svg" height={56} /></div>
                  <div style={{ fontSize: 12, width: 90, marginLeft: 16, display: "flex", alignItems: "center" }}>Knowledge Partner: ILLUMINE</div>
                </div>
              </div>
            </div>
          </div>
          </div>
          <div position="static" style={{ width: "90%", maxWidth: "1005px", margin: "auto"}}>
            <Tabs classes={{root:"tabs-root", indicator:"tabs-indicator"}} value={value} onChange={handleChange} aria-label="simple tabs">
              <Tab label="YEAR 1" {...a11yProps(0)} />
              <Tab label="YEAR 2" {...a11yProps(1)} />
              <Tab label="YEAR 3" {...a11yProps(2)} />
              <Tab label="Fac Guides" {...a11yProps(2)} />
            </Tabs>
          </div>
          <TabPanel value={value} index={0}>
            { buildappDashboard(channelData_Y1) }
          </TabPanel>
          <TabPanel value={value} index={1}>
            { buildappDashboard(channelData_Y2) }
          </TabPanel>
          <TabPanel value={value} index={2}>
            { buildappDashboard(channelData_Y3) }
          </TabPanel>
          <TabPanel value={value} index={3}>
            { buildGuideDashboard(channelData_fac) }
          </TabPanel>
        </div>
        : <FirstTimeUserMsg user={user} />}
    </div>
    <Footer />
  </div>
}

const buildappDashboard = (channelData) => {
  return Object.keys(channelData).map(key => {
    let channel = channelData[key];
    return <Capsule key={key} cardData={channel} buttonText="Run Session"/>
  });
};

const buildGuideDashboard = (_data) => {
  return Object.keys(_data).map(key => {
    let __data = _data[key];
    return <Capsule key={key} cardData={ __data } buttonText="View"/>
  });
};

const mapStateToProps = state => ({ userState: state.userState, auth: state.auth, channelState: state.channelState});
function matchDispatchToProps(dispatch) {
  return bindActionCreators({ activateSection }, dispatch);
}
export default withRouter(connect(mapStateToProps, matchDispatchToProps)(applicationDashboard));