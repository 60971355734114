// import overview_faceTheBrute from "../Tools/overview_faceTheBrute";
// import overview_sameSightedness from "../Tools/overview_sameSightedness";

// let {posts_001, posts_002} = posts;

export const units = {
   "uo_001": {
      "title": "Introduction to the program",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTTel90Ay5-i51r7bE-n_xWelKpwIa4YTw4T0DcJeCs4AUlr9jw4qqe6Kxuurc8kjbSwUvOPNHA4WqM/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT2ghzKscLT9aWxhagMYZTKey844MtyZXSmede02qLelsuItS_gFydo9__fHsfQg8YWuJrpxsvtm0z-/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_002": {
      "title": "What does it mean to have faith in the infinite power within?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSJrgfZyZym77pIuIqk3NHd6T8rLTCuaUkQO_cfdqNW50vKJ-qBaG2hhq5YT4PswI3xfJA0SgGeHpPt/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ242QP68GAD-FoaffkU5EDEtJ99Xe8wv3YK8CuLbuyqjPO43AeBZr5aj0-08HYhPMk7I1JUSHJ3yPV/embed?start=false&loop=false&delayms=1800000",
      "time": "[40 min]"
   },
   "uo_003": {
      "title": "Application Quiz",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTPKEfCExkmkFtnV3c9GpxUoprdA1b9wPVD0WNzzyW9tQRZpOA8ATNu6x-ZoHrDkSKeM65GkjDIWxqX/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT5f7tSa63ciBJLs5ncdjE-tiUkd9GCuqstBnF1ChoH8TgI7w0fLUrgHNSZ8RcLFfC0jE3yQLuzt7g_/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_004": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRLradlpBY2eBrZCU1E_iIUG6vSWHMn5SH11RdME2V8asBjyUbQVa8m5YI9HD4h5ryRecwEjWv6rdw4/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT2TcCBCLL4Phv25U4-AAzKatSuqtd9oTKvlLGRwg_f3ooBsSaWUWGcZ9ipckoO8nDcdluEKmqTi-1C/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_005": {
      "title": "Review of Session 1",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ7PeDH0RJRnJBaMW9me0Lgvd6Zt9F83SzPNEnw-irAWfNoA-TESDR0CPcFHPOUBKksARzfptj35G4e/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS9HquP6XwbHh3nWLDO8llIhLqKrHCxi17xEpt-m9lJGsd4_Kl0FQK5d9__LWm41YD2DmPrUydmYwvG/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_006": {
      "title": "A quick introduction to universal possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRSbeGVg7AWjj01HlXzahiQfv4o8bmQdWgENAX9AAe_hjARLhb4t3W85OsYjTZFyv1ScE7O3fLJhb4v/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTRY-hpTubSCeinFvgDz7lS-PH3wXpHqQVgQxKQ0gbo5Ftx1sjmcGkqmYWHFkAWPnq_WvQGxSRt_s-Y/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_007": {
      "title": "Exploring universal possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTzRfQtAtefTEuHFEgV4_xIXhluBGJKJ2cfg3U0CyvkfIFfXjJ1CdyKRzb_5CAyr1p3MK3UIrD6NgWK/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSWVXJCB-v0yL7z6MBwkDDjO29Nk9TgmC4tMPzT9MpqfXnNLjMMoSG423oFgR7lINE9XHeSlPiNmNOE/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_008": {
      "title": " A quick introduction to unique possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQMFgIidU9wD8EBwoY8Pr-DDHV9GO6j6bf-DprHgOJb-zcn85w3tRWuqXNFXjqhiLtHwWbeR4iDfwaI/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSDrpqcl07kaerqkQ2b9hcMTAVwSk4INQbsV8P6T5rE1vw8wLnhh86_wLC-oH_xGbqV7268DxLVmDMa/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_009": {
      "title": "Exploring unique possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR4eoOM1inQ3lrxdIPq0meCag7CTUip6HAG6qeXOq95xJ6Yz6bTAc6OSB2LWxOVAYC3_Z0h9WKKO_cd/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR17LQ3sVeLy6MB27PPXSxAzuMtZBfiryZ5Wr6ZWLSnfsBjDsYrtapbPW7Sou9ZyUqz2mtbsz8IRpXP/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_010": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTIAUDU5xGhdOCuwxh9TAJxNeQTztKG6jqG2_C-fhzpQo4Fi2w0bCBZiqiweVr4Vc13xQJQlI-YLR-V/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ1Ja54X8W0FydzGM0kIBspI22Hwl3wvIrkWdiuFfP8qwpTrAoaxFRsahXP3Dves6ZRCbWMG42pr2H4/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_011": {
      "title": "How can we awaken our heroic spirit in our day-to-day life?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRuw_EkOJuQEQ-d5WrtNcqPE8oPD3rN8B13ATxnqi-bZPiOoMXxH1W0NByKAwhEad1oi0kSpJagzEvp/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT_bNRB2PFS3c8G7-FsBHu2jMlK8Jg9lrLHkQqebkwhbbkE6by7_NIZSShUmFS1S43hpF49ZzHCDNvd/embed?start=false&loop=false&delayms=1800000",
      "time": "[40 min]"
   },
   "uo_012": {
      "title": "Why is it important to be heroic?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQT1DTrV7PSl3ivHgngbjV_kGMxHvNAYGbRKhxCzfo1SzwsBTmA7CoLOtPqwUdG0Ii5aqMKJ4_TXHz2/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT35Thq_Nfhmcy3sbb1WNkXupY7RjU-Tid7F-ZTGKtKvNaYO3-wF9iVow_fI-6PsdpRUMlHBOym19ux/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_013": {
      "title": "Meet some heroic individuals",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR5o3UBUzaaIOlLGSa98OU_yNKiNDYVCNCHd5TB0l0LB7Ts5gGQ9P3p74trM5zka6HOKHXzpcOEcfko/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR1zbVvHZNQ9vhi-04LMqjLb0xFoBXHsNlW3P2eT07NuX02kOzfbRosMGY_at4dNvkFK2zCpw9-KunW/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_014": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRLH3QXcq8k-0SCPKAnpnijozHKrpGEoZR3bP04Dio9CpIULBIs40VQ-doqerXFCeJz_npTNfAobe1q/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ-53zq89af6jLZJQxkcvDBLN3XvXxYgtHl0759plD0727I3D5LaajPL2sh4SRtfxpLfkHJvCDr-vk0/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_015": {
      "title": "How do people expand themselves",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT6DfVxQJkytd1y9hxqDXyEuFRYSrkyxurrTebFDvKiM3oWCh92KaiGpNxLrCufp0wqZIsTgLfwa8jh/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTFHKH2VjcEjxMowm2PbjkMo_ssJH07hwuEhl8wkX8Xpdfc6xdCQcra4aHBjOHza7FObVJEht1_Hyqm/embed?start=false&loop=false&delayms=1800000",
      "time": "[25 min]"
   },
   "uo_016": {
      "title": "Expanding ourselves in day-to-day life",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSUw86vZDwszhfaHJkTs4DsN6Vkkpi21XsSXlcxJ_P44yK8OyKNQ5sL_JkEgx4XpQOc0ZzTZfXy-pIU/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRnIo0AkId5v8dDg90YA0F9azkxTT2KpSMhJChKLu_zTYYayASPjKPR57lVBiMXsX1ZwUuyMnkFMhkX/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_017": {
      "title": "Why is it important to expand ourselves?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTabbteLepnZ8e5DBWR2IQ3SKvqxoc6oN8aD3ZLpoeApnh9CXJYTukEaqWtk5NBiURCxLBwmjx2QM7p/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQBTgtpIafl4lXup5GdbWHyT4r6l-pQzkq5__BYT3KoS98d2EG-aQdrbxjYRUC98to_0UMKIDPcRTf1/embed?start=false&loop=false&delayms=1800000",
      "time": "[12 min]"
   },
   "uo_018": {
      "title": "How do people live in harmony?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSsgaXg6mgrrtaD1Gn7MSrjdyzJ7hPuX_53MmnotBHVOzKsu739SybWkHRTP3175bRlfVKoZPDSgAre/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRaTG96IdOOXqNHn6NE_7_P6QErFZaEXl7Jx_xPkeOkHGX03BvawtI8qmznBKLzQk1Ou81PP4Nosmrt/embed?start=false&loop=false&delayms=1800000",
      "time": "[30 min]"
   },
   "uo_019": {
      "title": "Why is it important to live in harmony?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRxJ5oKesL6JvXma4d0MM5I-41m-oQmmSgkyXcIb5U0yZycwJw-ZKRwcCXuD4BUiAPB4uyvf2jJNOJT/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTYdymCZEsqx6qlr0H67MkdX26X6a8bH6qzGgu6OofsWGPAbkO3-MTZItLGwhSP5h7kQlBd63QkP43r/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_020": {
      "title": "Quick Quiz",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ9e-mvt_zNvXJQOmN2mk17YI1_P1UIxz9I_vEuQyhyXOfT7z4teAqBJq3hlPi1GfvCNL28BEHDij98/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTq5GryUzRoLRF7pMp6ZKZIjKodXpOmW6tp59hwz0ZmyzzQhpb9oHFQ0PXNp6vhxsDRpWm1judOZ8H4/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_021": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRoYqX1AnH6XX4OCXzQXxl4qbrByRoAWcFXc2SuB82jLJoKwmfhWXcqGz_Gq57B1dhaT52PIjdcziFZ/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ7X9P9xjaEEDbnVo_gDD4Q_6COkN-fP6_TG1PG64VT1lxG2A_KIbmB4psVVMTrdz3mVwtPlfuFlQhQ/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_022": {
      "title": "How do people seek perfection in their day-to-day life?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT5oUiTey4ZQOrvG6pmXeAlVypJ_51tea-DIO2RAvaEgRBqfv4F_VyAbbrC3nzvokk-xnhNu_d8VyI3/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTbSoeam_WtLePViA5IgAu8GbbUT7L2ujzfsckjq0DKRlKLmewJ-l7bpQyrsPr5N4ZwBTQIkREgVljX/embed?start=false&loop=false&delayms=1800000",
      "time": "[30 min]"
   },
   "uo_023": {
      "title": "Why is it important to seek perfection?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTb_7KvB7Qb1R-03dP3mcqPJYsrysrZWJ6_clNgpMeXqNmhwvlwps2qCNaJxQjCEl9oG8nvmfZ35RPA/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT1A1NwFOTaQfeIRU8w8DDJdwksjB9Z93pSCE-I2040TFqOX4Jdvg_9p4kPsSpzgOdHb7kmTTjLxfNk/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_024": {
      "title": "Meet some individuals who are seeking perfection",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQBHW7O8fwdJKqEfFzd1lgi0sKifYfDkQLKwcI7gdlZYALqwtBUo_LGU1dH1r2QbGPC3fuoWD9iIIUx/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQWm_We7YNIoW4i1DKipcRj6KwltHDCm3GYPQ7BxovT0DD55cxEspXkJiKZHre-NXEtOydXMxeN8bvI/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_025": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQhKiaCpUq0OTB25g6JBOWQIQM47kMS4vGWlt_c5A82h2orgtjSh7oIVMBMCe09lSFjBJuqqjRufH2j/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRk2kEl5dXJj8dgsr52ozxu9vhwDJFo8FJtln9wABZt5M1etqisEjWbIG6C522EnkT8QVUUzZV13buc/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_026": {
      "title": "How can we develop shraddha in our cultural heritage and traditions?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTOSiIBVED-bN1GA-H4-Mt1mSclX1nN6NyVqKacQU4TjT2iOFIp2qgga6l25umy2QoxzQJEz0oVJHgq/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT-WETNgn2BnSKoPTIXWG9ZlWyxaSH5auU7MXJQNEuNKa3svor2q48eBOXjYup7RsaUKgZNTOSQ0KVZ/embed?start=false&loop=false&delayms=1800000",
      "time": "[30 min]"
   },
   "uo_027": {
      "title": "How can we develop shraddha in our nationhood and democratic traditions?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR_eW6hTaAk256nS0xCPz6nv9DRH9HqxAnrNo8piPMQBKKHGQfTA0TA_uMNuwGoWj5F6WP2sUXu590X/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT-nakOLsamwORJ8-3Bia4hxFX2DdN9e06HuFALV7xaOMGISPpwyoyO1eosFRu0eEcQvVVzCySQqC0c/embed?start=false&loop=false&delayms=1800000",
      "time": "[30 min]"
   },
   "uo_028": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ1IQ1rI0H9_FKAmbklTQaLfA7nV5i_VURcrav7kDv3zpkvbk7bQcYkX_9tsYgXZIoLR0uqfsCFbCGH/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSmYpDP82QyE0q3qqyzQz1LhJUth1OfXlU5v2L12pGhj2oMqMXQ8uW3L6rVV_l2USWts2Mw7_0-G_74/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_029": {
      "title": "How do people search for the truth?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSz9kwcAGrkxhAxhWccoXAY-nnIX4Ldl5FMaoqzGHEas8LaIZQZdKQ6NGbKS-beJhHF7CIAw6DDNJ71/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQeMNYr4oXImW4omC9iNBv0TwfLKbBI3qCUwbAZLZRQrqMtLtvZhEUBh0Shi9q3-ecTIFnpvzo27BMd/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_030": {
      "title": "Seeking truth in our day-to-day life",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS4RikP9cmM4WP9ENLf4VMOnRN1uceyAz0H2qfOkLJ21a3rWMasWIEwOJUJAj54oVhhaDQBLJ_UxQWq/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS58IUk7jJONogJyuNfL7KYeAHDsE4c4NapY6L6WHLGF2oiMryyoN76JwtHQrLSoELBms5wWQAghvit/embed?start=false&loop=false&delayms=1800000",
      "time": "[30 min]"
   },
   "uo_031": {
      "title": "Meet some truth-seekers",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSJsHy_qpr1hfqIv9A-tF6FWtQC8gf8UhlycT2v-2u2Y5ngW4qxdWxtb6ZeiDMPoOhUnJBwJvpKQISJ/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRFZsr3uDunDnosyJULcp5Lhwxn5JXzqNE1gw33d4reI294Zl1CuPjEfuyV85ac3Js4a7GdhtvbBAP8/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_032": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQby7KRplY0se18OePlKaIatZUUrJBS2YNZKUlb58DRKwIjNR8j8ZKdnZx1QVy9-K9euqlLaZKpQH7b/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTC97qU8dVqUR1jGk97D8CED2TZV4-BdzyVcMW08kqf7IrALeVYBcOo0m3WlFVOTqBIJiko_6upEY0T/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_033": {
      "title": "Review of our 6 universal possibilities - i) Being Heroic",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRTh1q0sXM--vG3Wb55oIJOv6ikns0M7MGNZdqq7uhYYhx9p-rmcpiTmiNne_DZmO7aTW_i4XePReyI/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQGLol7H0eJ7eTHC0uAJxw_OF6oEvVE8wv0Yel71p7_50uJZdPaJOlpwsULu87a_K8rbKeJDZk1bhGw/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_034": {
      "title": "ii) Expanding Myself",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT5XL-N86TQmr14-KZQQALw2mx42_Op7OCm87BtyrQlJjU3dHAacCFcPG4Pf1ywwWfHiJD9e4tp8Pp7/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQLsrRGaSx7BOvu3Ig-XpXGOOrTynxFrS5M_uQLbHrSxjzEy_2xNTtflcGjif0mcN-aXLx-5GIMopLR/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_035": {
      "title": "iii) Living in Harmony",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQs2v8jJSr4ALxLOWVPgzxWc4BCVeTdtjHqeCWJBsaBXuilbFFbg_JY5uY6JXHhSs24S6zJOkN61luO/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT3wz4o7h6asJ7GOCvhpi4GZVUMiIPfvYAgdComco22TXSlXlBOcR7UPV1TWIurnW7KT8mwt2tLtliF/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_036": {
      "title": "iv) Seeking Perfection",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT6gVh859GXdHPIIjase8pxXjz693mRKWvrt0H-XQLoC5n0G9dOB1ZKDMWfjMiVMwV4Kjwo7Ha9WKsU/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT1GjcvS5458G147hxACSNLyWc0863N0Yn22hAj0PagfpF05CT9T2Ajw3FfZD0viQUD-zI0EghxTq6D/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_037": {
      "title": "v) Recognizing the Sacred (Developing Shraddha)",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQEIho-k82t6OTPOiPjdPGUAF-lY0-AWX3J03pKfEuEJRSlphRTZRE3uzQ6HCezaY58FX0je1r-g9-p/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSnBNQWsCfzumsStgEAOAdhRhJJhzVHJ6hCBddeJOjM7vLFRcgmaCTr-BAFRtL688UkZOu-MIg6Rx6d/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_038": {
      "title": "vi) Being a Truth-seeker",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTqJX0GISc6L_dS_wNdMrpxVh0QYqFj_T_x8-tm6mISKRU6576g20G53iOWfIE-_fQNJNR7wp_MGW0T/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRQmDtuSL3leKmXcdvjYZToOhOZc2-FtUNZ_WFRdcGHnbUirZKE0b2NUTj1ERbAbkWeNr9xrqNtbqWH/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_039": {
      "title": "vii) Responding to situations to unfold our universal possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ3bh1bYxcO6Ba2gGZXPTHqV_cUeEqtKqrBfvB77jWW3Gi9LNDCDMfyX4VOFpiLqwwpuq_B8679g8DD/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ7sq4M3UO7yIQWhdHyzXpmImKcjSRbiABPI22Iz26VxMwIeWtjHTOwOGKOSnMUR7sjP5YH4Ea2GETU/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
  "uo_040": {
      "title": "Introduction & Part A - Unit 1: Exploring our possibility of playing games and sports",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRo_kzl47xN2Q5-i7lLrtdjr_XkFdbZW4Gx4RrcKM7UJ0ivwz0_jDhM45712SL9G8Z8-4fh77ov4hdT/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS9rGNo1tO0Mej8ivEU_-ohJ4HvoFndKPxZYsMGysWIr-eLa8PWNXoOKJMmXtzy3_kYhOYg-5ioY5Vh/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_041": {
      "title": "Part A - Unit 2: Exploring what happens to us when we play games and sports",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSrqViXcWaaGFW2t2DiEP9DkXWhfUqhJC8k1bLeaJdxc1yx6KB4N46lThr76XHMNxLp8dQFxdxsE3m4/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTcTsIElml-M1dS6xJw2myM6O-1yTTnyDhVtnrxw3RoTcGFJaSlTaPvn3L1yl5s2SK6Pbs2QEDdjmT5/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_042": {
      "title": "Part B - Unit 1: What does it mean to adapt to various environments?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRQARLqcFnsnlD1sIR3iIt_6JLjO7hVpydhhZefSS0kAKpQRQwNrgorxfshXDKMN-Mq6_AhHlAYvygu/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRj2RiA2Ge05LGtoR-1DcuzagT3vwVQ1MuCalB7IzMZ4RVuAZCjcMa5RDKSDAa01yapFv0aCsgbxwIw/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_043": {
      "title": "Part B - Unit 2: Exploring what happens to us when we adapt to the environment we live in",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT7PliDtXZaeg-W20YHw5Ksuc-i2mIJvJXW8kjvHRgh02tlV8U0d9_53V6MUXOlSiqcjlM-hlprg-wq/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTit1xb4h25wAOhWd2f6-6wEonQSDlwSA3cRsdGQ6M-gHH61NLjqNFXqqycv1WMT-bT_XeEWep8oQGG/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_044": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQmOZpsuc__vyeOz3qvUB8e2sJcZe-xFsItQNy27LMsNT6UGkBJB4Zg9VmQtJLCebDEtwBcDvqJuAJn/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRazoWgVQma248uvdcgSGg79DUq1DzH5nLaoy5aAGLnOe2LFsLpOamKdR-liSjIZoS2_EckN8uyQp_d/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
  "uo_045": {
      "title": "Introduction & Part A - Unit 1: Exploring our possibility of expressing ourselves",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRwS4eD8_uwCf1PtdUUu37NT1pWks7cAQWUYm-JO_DeGjK2XDX6OBeCO1CXPC47DuhUXoBTbx4CHzwB/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQCv7FgNWnJFn6GBsapVdyV0airxjh43WqhUr73zahKUV-QZ_tXAX7mCvnxkpyGUlZLw7WezK7gfqAU/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
  "uo_046": {
      "title": "Part A - Unit 2: Exploring what happens to us when we express ourselves",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTfqluuRmzLArg3DT0KzSHAOLZrkxGkMYQex0vnzYydMyFRmuhPDzwEJ9JL9DXvRy_663xEQlc3s9jX/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT4fKobE3E65THspofCaCstLQee5MyxNqsuGMkHHSOt16MjURC2DKzP_FyCSGPZQ-YRa0mMpOIDJoUV/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
  "uo_047": {
      "title": "Part B - Unit 1: What does it mean to engage with culture and society",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vThieJgTG64f-pu6CG7o7WarKtrhL0wH1OjgBkS5aAAKtCm23dCc2DF8Twnh3NhOLkIjn56-FJHV3KN/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRrVEkXI1P6KtckUDfjG6DZt7rA43omntYm16gXfpM9JDzaVC8FsoKLsewSMNUwCPljAKnRmQhgkd8n/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
  "uo_048": {
      "title": "Part B - Unit 2: Exploring our possibility of engaging with culture and society",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTp5xi6R_jeSYRiaFKtvksrNzQcLm_5ObwtGCJRcIpy01L2gDoVnEC0lbBzbtPnXADSZ_ioTkof4Ya-/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQrLbzCbMH-K3PvV_JcDe7cNackcuO7pEcdDNboc27M6x4JmEe-SYojArXf0gaNMMwFmc98ZeYfpMhs/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
 "uo_049": {
      "title": "Part B - Unit 3: Exploring what happens to us when we engage with culture and society",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS_QSzgytWdbnXbZiyFF-nSfG_3HK2Oiwfh6DJWF-zt2ufWeQwSJ99MGdQsWDIAEWVSWJTD2gac2xgx/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRn2U5EN29FSde-dx4fBJslFvuGemqOqUBSBPozS_AfOiNs9y6lP4AyRwCqBvX_QGKfYfPOK0MMpoKk/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
 "uo_050": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQpES68ABCQOdOIOTcFUq-YEjVE-xJInz1leArdG3FlfxFW4sF_A-JWp-a_qeN0nE4Rx-JKZmZdE-BZ/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR-mRtUvtojHgJuyHdneRO0Rr1j8zs2AmxCYr7iZAUxZdCRn0LU_TiSbf-f13m1gzr-TIwtcAYgFGI4/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_051": {
      "title": "Introduction & Part A - Unit 1: Explore the world of people who are creating value",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQg3TJ56t2HuBzlUC77so_vXzJA7XoIMoFVUzMtAEAq5qvr5ObVYDJVlvocido6eiDWhifEberhcExQ/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRHjaJrvXap-1_75SapjvldASnV6aLLd7d0uoN9foieoSTHhnj-YP6_l7G_mS2wz2zfZJemNvNNTc8M/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_052": {
      "title": "Part A - Unit 2: Why are value creators important to us?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTd6h5QcI50idOfu8foWiXNp-M7Ydnyyq4j_TgcAEJ7pYejaNcqFhA0El95cvx9j6RMO7yBjamFv3F1/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQf4V7CWdu55DPCf1PtGBpNIWoJHkCYiAE34J1s6klK6zUvuTx3zzbEkMbNK56w7-B5ALREZgJme1jT/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
  "uo_053": {
      "title": "Part A - Unit 3: Exploring what happens to us when we create value in the work we take up",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ6jBYKdI9BUW4iVVKV8auP982XzyfMoAFzuXNg3ZS_JaMvhcxqw-jSIiLNdxHNEDZTc6B-xJb1Cr6l/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTJ-xcZ4sDG-g_cbxuR5W7kyeb7SxuOoYCoyj2dhj3sJE6wmVxgYUEOQ-E4NagdhgeynUeJ0sMWIAWg/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_054": {
      "title": "Part B - Unit 1: What does expanding the limits of our world mean to us?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ-Qx8r8IlsI1F20j8Ch4HM-xMwBvTWu9wFbysjNUKEyu766msBJ9m62dx4lO23T4T5QK6ucHYF-Sbr/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS7HGbCiEkrSYcEh6vCFx7TCZqP7jmJu4-WYfUX2q-4Y-w5Lrm71iD1dOv_L1YLnWhltYM4ds6-9FhI/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_055": {
      "title": "Part B - Unit 2: What all could you explore if you decided to expand the limits of your world?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRTFvZj2dkGRTLjwyztOooLmUAl_znJWM3QOCq61zazqFYAF0Kt-gor6xLGapmi_ozlrrg9B6nYaXMJ/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSMffUPzimgN8yUGJoY8o0qjtCFu2KhFuq03FyXtaGZhaEWPKXHhpmQ-PTXDpc96Mw-Vzwzg31sZx2f/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_056": {
      "title": "Part B - Unit 3: Exploring what happens to us when we expand the limits of our world",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR74dxzjqiBE9dRjrROJ8qZ9e2AOj3TJtQcIKIS3zY6Ku-03JvyxEw0hqJe9j44o_UnixEAzlMVMFa3/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQtLCOQHYlnPjVJenDnad35wSyWlzB1QcjWw-V0SG-k6WptPeOjjn3F9oT-yQ0gr9Z4qO0xcvnNCiw-/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_057": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS_cc783J8ZqBXD2dDx-BedhEVIEcOCrMDlCTFRpQ4__-7CegvJTU0-3KMNEvRNQ85R6Ff1HADhxY3B/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSrjkPuf0q2jIY1a5eIf8eYFIj8c8mZG5lvhZ7_SsZ9h8ESg9h2_p3Zz6e3IK2ncA_ys39-ZDTfUz2T/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_058": {
      "title": "Introduction & Part A - Unit 1: What does it mean to realize a creative vision?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ-qDaxg_6fGnt18Zq-rwqU_mydBZi7z6cjG9uY9_capsoKDDRYnE422K58YP_c7_PyKJv6bKvuAXju/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR4JfMzIDkexGlNh9d8oUmIkw4OIx2aWI600uvRlzK3Hi7yBCCrsv5J-2CAqYDPs2FVQEaTw98upXvu/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_059": {
      "title": "Part A - Unit 2: Exploring what happens to us when we realize a creative vision",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRGu0anUmpdK_eB5LKcdwZVkAf_JIIEmvyocCQq2YMmqKHlLkEy91eQYtGDuelRbHTdUE4dXqqSG73n/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQpqWCBG3NC2prkopqBzELjIKJSk-a_7szGGQJP860w8MrHoPqHtbVVAVb6l69SYveof8ekg4qo341-/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_060": {
      "title": "Part B - Unit 1: What does it mean to work with collective intelligence?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSQyCyi9RkaELTqwlmoXkBQFGKiyP7FvbFgtQS4HXhyl5gXpwy73lwwUSD8n3SJmi8aFkJ1uMLi31AO/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQyFp1TqtgUBYpwlYlorMvFUw4Zb-ZGMXVHNHEw7CM_7Tii7uGIEq_MHVUZKM78Y0IG20SYyY1ya1J7/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]"
   },
   "uo_061": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRdV5gq1Iu9jAmqYwA_H0SYnhd9vfytW0KQovXcBHaucnXdGwzFP2HSIqEBNwHMPMfI_PaevtBkjCPN/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRiRsNVWET1yRc2o9bxLDP9KVUKYClPaAAIZ-8KIAQMGWKBLOZtAblvGp6eL8PfZHgA8i1N6As1j1sd/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_062": {
      "title": "Introduction & Part A - Unit 1 & 2: Introducing the possibility - what triggers invention?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQLigYQeMk96j5cBC7pfql5LJj7I184NVMZsmeMnO5fIHuK8j5c5EbErPTv3GiY87JYHr8owhObVf3f/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTGRN3nbh1al52QA_AtzRDOn9Ti8S1_LGxL5SayQ3uv0y84dW8pmdYdrrf0HSDFUQEpkY1CJ3046wF5/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_063": {
      "title": "Part A - Unit 3: Why is this possibility of 'inventing new things' important to mankind?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQatW-ZvWwVdRwqK7fnyxkwBMhPsptzoWLLeQ61R0BGn63VF6wP9quKRmagbe6jNIjDhokvAiQfbjJY/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRPffLEhr7GSFVq1kI4QFk0cbDSplwGQmIK8feA_E7ltI4keOx8I3_F7wB7rTHxvydzxt20umDVd6Cy/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_064": {
      "title": "Part B - Unit 1: Exploring the possibility of developing solutions to complex social challenges",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSEOAyL6m7-o3fGbhcDDF7qsgl2EZm8LpAy0vDGy6siQpw7-EM7kaMqn5LN5RpLMCT8zKEaZY8-HPX_/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRXY_GCJsBqPd-oRW3FIbI-abJ3x-4bGT-eLBu_icJT5cGTaXSTG1k9K4M28vonoOyn0zO5TJLoDSW4/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_065": {
      "title": "Part B - Unit 2: Case Study",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRvext3QD4525DrhTjePRqWdWYdIE-KReVCoBIg28qL9hlgkHtr-GTOCBuRIiAiIcnHrxVb7IQwCp-m/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR1m8qsgEusuTm7ZF3bNtu7Y7gtinrsp9dM8h35WJgttQgYQko-ntG-CeLued59KY2x1gKOLCnTAwkK/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_066": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRyo1TZVCcHbqrQnRDG3essSFdrbqGSvNWwSKPbXIVVMlzlLgdPuMSPb5jIcmO_G0stoFXkyPDrEqum/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR4FH961eiDvIRViLLBtH55UPnUFGAthY3RRlEYcotqbhBGNCwA23Zi3BM59MlChtlSJTqJzEV0aWua/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_067": {
      "title": "Introduction & Part A - Unit 1: Learning by continuously improving myself",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRG1RDfFGHJJ10x4v_ZRy3DpKN8JDf7CdJjeXZHTTLqJdFcbmx2rCRM3HLhWst_UYCJoRvSyTUaUdcV/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSUG15fwhQEFUv-lCNljL6CL9h-Hja1ABaDRe7dT-z_-t8MMROxASEKXAtDsr7ij5tkbtiQq9qKlOSg/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_068": {
      "title": "Part A - Unit 1 (cont.): Learning by becoming aware of the world around me",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ0bY0tzEhDhM-uCxxRu5O6qK7_7bN-CNFuGG7C-B33JGlxp1SjI0pETFuRNF11IAtLt5RZeGCi3aK6/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRutqWSP25iR5lvfOQvtaTOqurJGUgskHLbPY68Q9vtt9QkaXvPLJ8sZ5KGw-1gQvJMaiS0p5e1-8k0/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_069": {
      "title": "Part A - Unit 1 (cont.): Learning by experimenting and building things",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vThOgZyTKW-9FaZom6u4SUQzf3Gz-ldM312S7YWnTHal-N6OxkR7V07dqZju1PWRIye0lxLsmyWS6IU/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSLYPCrSy2r68I9UEHCXuFAMY3IwKkTfhqloKTmkwNt3tAFnvFOQHKlY_ZMeamA1K2aqOb_ZkPoOI90/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_070": {
      "title": "Part A - Unit 1 (cont.): Learning by connecting what I learn, into my life",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSoueBjL21XXKSH0sfpMXR8H2ff9flDS32hT8BqkKclITKQthN6UmFy5-8JktvEXATtX1itkjfFfdA2/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSKAaM5aoit0_16jCcZVsUD9PG7qi_qCcalFevIj8LT7A9z1wTu-03Oi8ZjVfxY4gEjni5BM-2UGjkZ/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_071": {
      "title": "Part A - Unit 1 (cont.): Learning from instructions or advice",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT4p41nC53Hi9NM8c9KXmHHIZ6HVGljOzVoR4KknptX1ZIpyis92C3wRNMiTVYK0oWEhtLXBjErdDmB/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTYlHXPCjYYqYg5Q7s93NI5LgHMFWele1uaSmfg4GHsU5aagmS8ipHIAhiYc2yDTRs6kiOsKoSImPnO/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_072": {
      "title": "Part A - Unit 1 (cont.): Learning by reflecting on my experiences",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSSwptsWVvSpYXJ24IB9-8CmwCWgxn2H9_INtKAcKRjIq3g167ik6s2jGgwuL60ULgihRJDJB-r7Mx4/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTeIVO64CdDjuPBAutV6LWbvlJBbFbl10UST0WWFgxT65gJMFwoWpQMKhRtH1qZXonXRRgkWtYWnb3-/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_073": {
      "title": "Part B - Unit 1: Expanding the limits of human knowledge - meet some role models",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQK9rUIPwlTI2K0eelU6yRwrFV2QEzBosqjP-cOto905oqf68OVuVNnHbjoW7U8YGc9o-NLRoHKPDBk/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQOeXSbLGT_UD5dw7RaY1lhuOxb_BqSRcpAtBQWaxCBY_m8d9_d94HaTitx5zNt5RDOhoPdgwvZifFO/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]"
   },
   "uo_074": {
      "title": "Part B - Unit 2: How is human knowledge extended over time",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTQFslfYX6sa8demPmSJJJ72JfJh8VF6T4oSX0NGD_GYPGqid21G1kSQtw64_YQrsce4wh1P39HH8x4/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQAvhuuhriL1tq0NRhB1t8AbsqzdrJOctwyCWp4fs3hLecS5hW-Na_XgETaBDcQh1gqF2bB2bLuGWLa/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   },
   "uo_075": {
      "title": "Part B - Unit 3: What happens to people who extend the limits of human knowledge?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSSQjS0sF556F53jb1DGn6EhUEW53ZMeKHSlRx31lu4V176Fq-GC0Pn9p-Cpi-ogHAexI2FbziGNAyE/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRP8CJBkPGFjwybkKHiz-34-CR5rtYUeB_5E93F1xD_XJDOd5wCN6EXYz6tZHlYmdoLh2bcoZJVmM2W/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]"
   },
   "uo_076": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSfCm1Rmc8ijQ3EiAhZ7IY7hjPX8xTDaGHIKNKuAJzxN6Gl1ZzrsNx3gskxULuzR-gFaHoIvg-vjsYV/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQq0a9NGfGI0oWJhPbyQux-Iya8HUe_osEmY6WKSbgzW3NloLjUm-qycIZRBCkcrE9akzNGFcQNk1fM/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]"
   },
   "uo_077": {
      "title": "Unit 1: Review of our physical possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSpUGP8s7EWe6cOLRsKUjvz2zNwF1Vzk1rxnMPft-fnajkk6_uswOdoHh3WIuGtWWeJzhFaMWKzrGjG/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSNvmZqsORuO-noOVNPrCNr5ABC0JF9ElN0Ztkvb4jBpdZpjhOETa9IfmZod2PmwWEB4At_X72W_dR9/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]"
   },
   "uo_078": {
      "title": "Unit 2: Review of our knowledge possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSYyZCpikbjgLFAf6rEjZiFBX4eiuVW8eAl6EAaCMAwJxs4XGuhPv223TfbpnsjospqU8RUyaJXjPFv/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT4c0cwp0t6wwm_sWrsBIJmw5S2H8G_UYrqSiL6CQ_RzKrX3UE1GeNzCPWnlQGRLUx5gCRRAMXcLnHc/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]"
   },
   "uo_079": {
      "title": "Unit 3: Summary of the Program",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTv6VTJNrUf9F97xHcOPb4696p8UZNaPaI_3OfqzYHGn_FW24-WeEToPGoIX3sWhv5QRhPwQtfL3HBh/embed?start=false&loop=false&delayms=1800000",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR-b6PN0pqPnal7R1yV6-jMnLeU29R_wjL0XocT4gb6tweGHZhtTUeBwkhNMHhaMluaUok-8EOUdKjO/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]"
   }
 }