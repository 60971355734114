import React, {Component} from 'react';
import {connect} from "react-redux";
import MultiPageTemplate from '../../../components/MultiPagePDFReader';
import units from '../../../modals/d_channels_fac';

// const isBrowser = () => typeof window !== 'undefined' && window.document !== undefined;
const duringLoad = <div className='loading-screen'>Loading the document</div>

let Book = class Book extends Component {
  render() {
    let {unitId} = this.props.match.params;
    let unit = units[unitId];
    if (unit) {
      return serveTemplate({src: unit.src, docRatio: unit.docRatio||null})
    }
    return <div>No Such Book exists</div>
  }
}

function serveTemplate({src, docRatio}) {
  return <div id='_book_meta_cntr' className='_book_meta_cntr'>
    <MultiPageTemplate src={src} duringLoad={duringLoad} docRatio={docRatio}/>
  </div>
}
const mapStateToProps = state => ({bookStore: state});
export default connect(mapStateToProps, null)(Book);