import { units } from "./d_units_Y3";
const { uo_001 } = units
const { uo_002, uo_003, uo_004, uo_005, uo_006 } = units
const { uo_007, uo_008, uo_009, uo_010, uo_011 } = units
const { uo_012, uo_013, uo_014, uo_015, uo_016 } = units
const { uo_017, uo_018, uo_019, uo_020 } = units
const { uo_021, uo_022, uo_023, uo_024, uo_025 } = units
const { uo_026, uo_027, uo_028, uo_029, uo_030 } = units
const { uo_031, uo_032, uo_033, uo_034, uo_035, uo_036, uo_037 } = units
const { uo_038 } = units
const { uo_039, uo_040, uo_041, uo_042 } = units
const { uo_043, uo_044, uo_045, uo_046, uo_047, uo_048 } = units
const { uo_049, uo_050, uo_051, uo_052, uo_053, uo_054 } = units
const { uo_055, uo_056, uo_057 } = units
const { uo_058, uo_059, uo_060, uo_061, uo_062, uo_063, uo_064 } = units
const { uo_065, uo_066, uo_067, uo_068 } = units
const { uo_069, uo_070, uo_071, uo_072, uo_073, uo_074, uo_075 } = units
export const organisers = {
  "os_001": {
    preTest: "",
    postTets: "",
    units: { uo_001 }
  }, "os_002": {
    preTest: "",
    postTets: "",
    units: { uo_002, uo_003, uo_004, uo_005, uo_006 }
  }, "os_003": {
    preTest: "",
    postTets: "",
    units: { uo_007, uo_008, uo_009, uo_010, uo_011 }
  }, "os_004": {
    preTest: "",
    postTets: "",
    units: { uo_012, uo_013, uo_014, uo_015, uo_016 }
  }, "os_005": {
    preTest: "",
    postTets: "",
    units: { uo_017, uo_018, uo_019, uo_020 }
  }, "os_006": {
    preTest: "",
    postTets: "",
    units: { uo_021, uo_022, uo_023, uo_024, uo_025 }
  }, "os_007": {
    preTest: "",
    postTets: "",
    units: { uo_026, uo_027, uo_028, uo_029, uo_030 }
  }, "os_008": {
    preTest: "",
    postTets: "",
    units: { uo_031, uo_032, uo_033, uo_034, uo_035, uo_036, uo_037 }
  }, "os_009": {
    preTest: "",
    postTets: "",
    units: { uo_038 }
  }, "os_010": {
    preTest: "",
    postTets: "",
    units: { uo_039, uo_040, uo_041, uo_042 }
  }, "os_011": {
    preTest: "",
    postTets: "",
    units: { uo_043, uo_044, uo_045, uo_046, uo_047, uo_048 }
  }, "os_012": {
    preTest: "",
    postTets: "",
    units: { uo_049, uo_050, uo_051, uo_052, uo_053, uo_054 }
  }, "os_013": {
    preTest: "",
    postTets: "",
    units: { uo_055, uo_056, uo_057 }
  }, "os_014": {
    preTest: "",
    postTets: "",
    units: { uo_058, uo_059, uo_060, uo_061, uo_062, uo_063, uo_064 }
  }, "os_015": {
    preTest: "",
    postTets: "",
    units: { uo_065, uo_066, uo_067, uo_068 }
  }, "os_016": {
    preTest: "",
    postTets: "",
    units: { uo_069, uo_070, uo_071, uo_072, uo_073, uo_074, uo_075 }
  }
}