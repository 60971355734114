// import overview_faceTheBrute from "../Tools/overview_faceTheBrute";
// import overview_sameSightedness from "../Tools/overview_sameSightedness";

// let {posts_001, posts_002} = posts;

export const units = {
   "uo_001": {
      "title": "Exploring the importance of awakening our human possibilities",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTQmfsISuV5TXBP8TBjlYEm_uZG5qeya-s3YnDeRbngecX60vugpuwIi_XEg0NhL4Xody9yCmM96hwM/embed?start=false&loop=false&delayms=1800000",
      "time": "[50 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTaA-3Oi5ApDSQDVvw6f4OoQUSSQ3Q213eTGe0qrhRwRG4iACRncOniKL2Z7VamhWMUbsianID2oe8-/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_002": {
      "title": "Recap & Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS-LXiZIsg_ZEcQalmU98xRsAsndUUxLY1jXBZuqlvqEJRTcn1sLy1SCvqmVpC1mdAH2_7sppxLfrvY/embed?start=false&loop=false&delayms=1800000",
      "time": "[2 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ-2NbpjU0r6Az9AYKzO8az-htNwAdE0W1Up7xo6lA7tsa6owsrxdSmN1NLtU-Q43rDcZHASXOGIrAf/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_003": {
      "title": "Exploration 1: Even if I have failed in the past, I can succeed in the future, with the right effort",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQgnvhbs74RRM-oPfCHFToXYSsEKD0X-jWbvyQInVpztkHFu4A9zuSW7tZk0jx43nNAjhuyXSBq40hu/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQkUkoBfV4Jue6JHLxGR4g3XntrZDVBAyN8RUlm3kiPtyKc8axx7zO8MJDmaRMCjwUuQOvHK6TRU6sz/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_004": {
      "title": "Exploration 2: There is no age limit for discovering my infinite possibilities and developing them",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSFPhEMmTn8DAplkM_gQzVTrTTeviS1yVJwhZUUdPN4-PmXcvL09nKNV_F0lNNPoKhehPRPTCAPPoH8/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRMd3tF_S4TAbLGn6TPX9SB9Pdg-wW77eFMutCuzrCEpOU9-e0q0AY2qjRuvIBDtdBaC9zaXJD5WxPl/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_005": {
      "title": "Exploration 3: Even if I don't have any unique possibilities, I still have, by birth, all universal possibilities within",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT1cR8UxWq1oHv6ymCpg5xIcqu_YdFgSzK0SYtKDu9idy8D6HdzMjL_mQTh39JdE4dmQcWckZfD8bAG/embed?start=false&loop=false&delayms=1800000",
      "time": "[25 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSvlPb14sEUzM-8fFyGhCpUodz2mQt0UIR0sKuUFrc5hbe-CR6vDcBjAovbNLBVqgFNva0pFvWvsFg1/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_006": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQV0Wxh-bPWE8noCQIZMLMH4nHcSJK8IASAivemoC0f_g_s892Bd2oMq4BKf1PS2M97kuP-_imi-xjc/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSE87pVGH2czbCA4a9xY5Hvrf-SKC7FWnC6ozT_k4lLIrwF2JWI6dtk2mq4PsEYipX5u7wTopkxjXb3/embed?start=false&loop=false&delayms=1800000"
   },
  "uo_007": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTfRXaIZXItF634bAymG95dzP2WqsYH2E_OkQXaCCQTrR8XZeNQNuwmnbskSTQHl680BH8RgC3JIlUC/embed?start=false&loop=false&delayms=1800000",
      "time": "[2 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRBaiZFH5Q471EbwZR-OQ-_O4aJvV7lYoe58IhGMaPIpkIhxDgWhARzIHD-i-bv24P0RcqjnJ_er7Qk/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_008": {
      "title": "Being heroic in dealing with the various challenges I face as a student",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTE7AolSsMbrbaD63UBPOj1kfT4xH3ssqWVVqFNVBxp7Kgo8LyulNfVLqIHMzguGPu7CuFzWqbBBL9K/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQsP4whG23kDTjQw60CxcwKw_vRh6TfRnPpRM6pPje44orTLhKjutFRJ3K77EwtGyuVUUKkvnmRhCJF/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_009": {
      "title": "Seeking Perfection in any area of school work",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSU_xIg0iiW1SEiy5wpgYNqNAJggspze1ZKi7hm_5CZTF3Y8XVghpNNK6XpSpOMGe4H6aqEE9Y_UJWR/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ6wXyoZIuVO44QOvSV0-GlaCyRNq-dP8037q7XMjinHssh8geJs9QGsNSGpzhikoC_2kBgE4cQEj6x/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_010": {
      "title": "Developing Shraddha for my education (my classes, teachers, school activities, etc.)",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTFNZNvNP3fH7KjFW2Yn6PzogKfMtpTBWOJSrW9KhuLohRI5RfVx4O8GBX4BfmaE1606aTcMQJCvIV7/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT6loZUb7eWCZpHT8bl6RMWTwzSCFwqdpHWyqlenYd9NTC7RcY4bYUkUAanBMBkGGqfQtVAOJa_NVOj/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_011": {
      "title": "Summary Discussion - Opportunities to unfold more possibilities in the classroom",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTM0veWuqxH1AIVkxwwyU-4pKM35-4fBUl0ZoFAYGiJrDrg-akZPOF9PfmSEYS-BMUnYisfNpzH3pov/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR8uBk2vtpbREatla6Ajvs122jGdT9rycYLG3ceYnrhhqThEFUbfCRNSvzIUVsME2Bx0_d84ABiNMtz/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_012": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTVBW2eYz_D8lgrYju5YGGXr7_StS2T2hdYy68o41SDzaHixQvruOZJU5u3oqi6o_YWcsr4r7DWRROF/embed?start=false&loop=false&delayms=1800000",
      "time": "[2 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQPLG8yppFUuVj5dHjeLhGtBZheOjLiJ17jxCNwOJ8u7-AzqLLYJd7iT23WYGOl8uuCaX_zjvshjecX/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_013": {
      "title": "Expanding myself to think of the welfare of the whole group, not just my own welfare",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRzRwU5EIAloJCvK8VCAF1kZS4M_Xf12JGgVj7ugCgIzF_mvZiAy85sTnhHt8Enjk7zSFNCMhSnO9ro/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRBayQEguiY6fWauwjB9dbQCVCRSf2c6-PBOrlVdTWy6K-rFb4oKlIWnyj47WBtAlsKDXYu5bwVPnD4/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_014": {
      "title": "Seeking perfection in my role as a friend",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSTD4qwSWJsabmY136D4kXhL3pU8TU7y6rwJ_nXQ9LJrybVZJr-xJqWW1_71jNWUCw0XZ1ms4kb884Y/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSZqApJTtC48-HsuCbzODQN4A_sv2EL7rU_Btev6xNtCG03jIYCesTtYJTf-5eZ98XDsrYPg9wEA-dK/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_015": {
      "title": "Being a truth-seeker in my interactions with friends",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRpcrTrHAkFuotYiaAyWeADQMUhfzdv2X0oMJV5AUyb6-xvLrHRlag7gW5jElVdDwUo4KFVp_C3ZXRi/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSLkFsKL6javpkOp72He-h2z4085pY0PxFXI4C9puhBlBHcmF8xKPgpEyMG2Nz9AD_s5ArVv98KE7Hi/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_016": {
      "title": "Summary Discussion - Opportunities to unfold more possibilities while with friends",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTdC1JYqFEzwBUqeJ1FUMJ8613Q8_gCiTEVcdtSZ7RuIPE7kN1PIsKyb1n5t4E_AhT4phGaE_Z_ug5w/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSX6YRVuUzWTq2YvfsfPheA6fHCtNjTlDY-ZjPlmmFHOW1S1kkgrwPKjXJl2ql0rBOubAsBdLQv82tv/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_017": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRDf48Nqc803M_NeYEDqXUpKySBkET7rTRBv2SuN0ICSnWZfMf3DCSJ77qayeeWcq_atOy9_iOXjagK/embed?start=false&loop=false&delayms=1800000",
      "time": "[2 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTQIQLAOAdWCapd7luvubh6XSbVxUFNvmTn-T8rqjTn14wwCH-DlIv8pAbO9w26JuSpKEefJNrkbBKe/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_018": {
      "title": " Living in harmony with our family members",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRLYD2fEdKajp4VEDSMSkvYuEofZEhVkaOsPw1iOrmFP-y5uLNEFcgqBzY6ApONaJD3j_LvnC0kTlQx/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSETaBVBfUVcA61LSWkWhVSWswSnD5ytGRoOHZpqfmy3cq5zTO0bD90y-xw3n29gHkdK4DLIJHBHHxx/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_019": {
      "title": "Developing shraddha for the various Indian traditions within our family",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRqUJiWW3HaWCcVB5MS2oYzmAEP3H0C71ZWlbXGystrwMpiGKZXARQ6r6YwVAmvFhvJckbn8byK_JRF/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQD8So5BvJUYiHbBo7hAcOwVdkVWn2qf6njuvdFM4aKxifyWSKClRt6OxZ4fN_NwP2O-BhVg4zL_Fbw/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_020": {
      "title": "Summary Discussion: Opportunities to unfold more possibilities in the family",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQJQ8HfJH6cLfdJ_WqV2NzCDALJBP3u3yRWQwhYe0_UkV9wZx1m1LN9iriqeE_WZmpYLBEraFEjTyCE/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQcauERkAa_WeRDE-RYGBHIRPYye6i1uJQgBt3wZBk-Qjp1CMZCXtmtWIxFauOj6Xbx_U7QNoLGcfRN/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_021": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSW0jlnPlxfnbRo-GPdci3ZIIp-fuYZi-fIQ3WSkQMVxxCslBhTM5ld0vCZ-wLkI8ehV1skNxopcryC/embed?start=false&loop=false&delayms=1800000",
      "time": "[2 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQFR7g8pwqFGqW4KlG9hUGe1HZpkS0dzfj32JwjTYlZUKHH7SOb2mcmPNI7vijLC1sx4R2snE4-mUp_/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_022": {
      "title": "Being Heroic when our community faces challenges",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS3VmqaDqTc7pE4yPBzx-KuTYpgebIIRcyawypxusBcFdz1G0zKZs9PvhJ8mFjHgrgbN11B6w2TxCq2/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSKVi-5i_fXA1FEa1dWoFaI5MnPkSmBE01C6hbotL8JvZKJGllhl6McQUh7EE4MxQCYO_6g3SJ3L3W-/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_023": {
      "title": "Living in Harmony with our neighbours",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRUY9jVWbNMbEtgOIowHREjy7a2ztDVTyS5my55nvkMM0rEX5EHydYWyJAfPP0ARoB6C8vmDMKoyJ-0/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSN2cBxyodpyhjq3XBy82E64ORkjKr1CWtl6PDU0JK4OgK8cAoZsXbQfvlEQaSre5iM4_iUl07FqcOr/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_024": {
      "title": "Being a Truth-seeker in the way we react to people and situations in the community",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQG9u9z9J8S57jlDBdeha5dRA0mGn9lcc7TnwoF86fJsxZq8nLU_IXxG12piCmTlJR7NYZXGbaBjSIM/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTXQ-GquQadsWJyhLgMGsHUGkFxvTgefteFZOAc-EuFqqsWh9WS9d9cF0DCUbYNkAtnqPDLJ1xvyy84/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_025": {
      "title": "Summary Discussion: Opportunities to unfold more possibilities in the community we live in",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSnwnK1JjjIX1gC4Mmc2jRq1IzPWVYc1xH-KHFixq69XZFBWogAF-1TW5TjrSCh7hizcY-KPylmR5oa/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSdChMKAyB7PcWSSAg6cv10GzY4AcIrYUKcywL6kxUSKehgCbPXWLczsO88Ee85FdxQ6HcAwT810M6X/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_026": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSHfR32lNhXtZv2kWWgC4r_VnBen8gStyX2f3iu2kpW9LRtHSf10Dn5B7ClyRV7EH15XTiMVUsbED0T/embed?start=false&loop=false&delayms=1800000",
      "time": "[2 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTC7iafUf-Kll2PImY6dt4lVzq9dYpMYiZQRh3Gc2igWlekWaNjNUZ2gGXmmgyK_h48911ku1CA0veg/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_027": {
      "title": "Expanding myself as an awakened citizen of this nation",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSeUYG61D1p54Fgatb_VKenILpcTulCyZMp1dl30IWiZIu8609joneE4FLH2SShs0UXN5sXVHuXQHvA/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTPQ0R3QHiNC8pULvnmzsZPdfoheQ84cKUvj1qVKTwIppUqidq4XtdZrVPTk2MDwQ9OGokPlbBSBTUq/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_028": {
      "title": "Living in harmony in the midst of conflicts that arise out of our diversity as Indians",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRxyL3KC8ByDysDldOx7ViQuP221lvRwl0z4-P5XcJNSXMdqOFw8JYurhh_jKPgcHD-jlpY7j7_SDPA/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQg6LIncWXJDka0IOxpbhckMR-kAH9MGWlyyakHbBgJXvQf8oF13c_MzEed1eA3e4Sjr5ekQXxVVIMB/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_029": {
      "title": "Developing shraddha for the systems that run our country",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRllmxzy9bjH306jekG3uj9w6DYT2oC6rUaaBUV4G5oBza-lzKs4qFXw6vkLLaCek8KuAaita8uwVr-/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRnVN2wfSX1QLmV4t-P2DbufXZuUI0OyUA2kiD71i01GSQvs9VuR5lgiaaMDPsN0wmeRDf19M75mSXn/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_030": {
      "title": "Summary Discussion: Opportunities to unfold more possibilities in the context of the nation",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT7Bisz-_GA-fU2BwTH0dOFRWMLEU3XsmyTni1eXdWN1VuYcJO5yA329YkfIBCfB8tlWQCLcbliOtFY/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSkydJhO2G--DOQlPYWBU9PmO0AnHipCI9rS4vGnEGyRElBYtbIQt-4p2YFP7mJbKYYHRh0wNoFNeD2/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_031": {
      "title": "Introduction to careers – what do you want to be in life?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR-0Z_TEMTok9u5pkloZYmygfeKdk8eczEjRxn_RUqrv1r7Piwf7HkQ_zRLJN9ZaGqRBtlCvPdKuvwo/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSj7XtFcQ2UbGC6JMcy7uVTSWWRB49xp6n_PrBrivxAVhVE8u0sDi2vOU8_Z_2JAjXoPYzUag5MTgoS/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_032": {
      "title": "How will you decide which career to choose?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQonpSbt-95Jn5mQbWnAnl9lOIBNpky8zamz2XFLfw7K7xVEfrQqLTjhq9N8NSLCQpgJAy89_TZ8TfI/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTP9zbiFeLCNe5yH-Q4oCBC6dLSpgATzBO-QeOIlDQsL_EZItI02kF2Ujl-Ha5MMRxc9p7VypnUsKpQ/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_033": {
      "title": " Career as a Scientist (Sample Career 1)",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRkTiO4ofz6Ufnwtg_erO4vS1VxgG-C5vuRBzZ1w3K6w7dcGATmfuLzTrvhd08ABQiJ7BdYanlrtbQt/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQp7m7X04NUJ0QMrvDGF9LlAE9VotOR9myqpV_bavmNVaj3wNoWSjJoMom6sXR7A70yF-EBAzoGgRdL/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_034": {
      "title": "Career as an Entrepreneur (Sample Career 2)",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS3reZhg4rPBL4_3fGlyyfZj3sc8ftASIAj8OGp5t6lx2VbwuB0nJi0snqkbHSxPMcx0RCCkPB9C1LC/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS78xlink_RrtM5asYpBF1oGS5X9vVaI7lEzcNE0HvTDiJcukQjhoZ9Q3Acb-SAK2SR1T00h1n_DOA6/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_035": {
      "title": "Career as a Farmer (Sample Career 3)",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRcDJenqyM79HMDlCjCzUEw4jGexDW9EudPIz3k9-LvhatIjBpA89IDB0WbYpQ2JH_KD0FtYJiqF7p3/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQrqC9VoqjB5dTJNfKxHgH3JStP3aPEbT5KCHHu2jGKc6o7f62NONt5MGpU9b5Xh_9IlyKeRO2HAXH1/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_036": {
      "title": "Summary Closure",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRtHDfI2rI38ljye7UaYd_aZTIFrxqFqd65lmMu2HS_TD0-VywTqvT1f-Bv-XRZm232VVP9Dcrk7UVo/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQaQ_Oq7fxea6l7igNXw1ntCz7VkPOdUphAM4oJBNlKlTeo73FBPTm5Ra7XX2UcuR-7sn3VkD5XYR19/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_037": {
      "title": "Homework Project",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSIG9H0nNLp2VdZ7WpSMfMSB7eGm01jXtP7h3q0-Bh1iYKhR1PrivWRf740_m_p5MLatYCbKw5KYyH0/embed?start=false&loop=false&delayms=1800000",
      "time": "[1 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTkod6VDk_WPgmod1a57Ng82Rh9dp7BWhSIaMOecKFJXJvKdT2hx8PoBKkC3RSuuFYyxStyUl2aLDEq/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_038": {
      "title": "Introducing pathways to awakening",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQokpj4uvw8HDfh5KZ-K4n3TOJZcekizUXcrn7aJ5YRYDGsFMQR9Ax-TYig9IKljsXJ8jK14FpvYx4d/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQH66Y8IFGFQUikfaoGtKVJeffHBh7inXTALovzcA63Yu4WOmgku-ThrjqYviIjy5qqHALrQms4EyU8/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_039": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRvtj_17fNRleD1wdR7zIEU8iDjVqT4waXnqvH0JlmW3A5ZG-XUPMpWPdYxOCkSZDeofr9EPX9qjD7i/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSlOgHNqnAKy7oAcc90xI7oo8vI9RwswOpkB72iczn6xIhTcv2zrZgawMVwaMrqEpwZhNm2OM1-zlyT/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_040": {
      "title": "Practising discrimination in our day-to-day situations",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR4ZwPeEj3attBS2DeVoIoec5jpcav_AUlJ9Fe207VQXuLz4x_O_fN2h1qht3Aznaarc6H3DsMlU6iZ/embed?start=false&loop=false&delayms=1800000",
      "time": "[30 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR-zp6I-HXnIU_jlCL1F0-BKxP8uu-PhPrXGs_wJsFWEREK0bfjeRBpIVg7P3GUx36Ws2H2tjpHZZIB/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_041": {
      "title": "Practicing discrimination when we take up a project or a long-term goal",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQnkhDhuVWinHAZtmGrSlMoDWIILP2vY4bgcCWZlSWh7jmEjvFTb-uyu00OpYpID199zIzF5oN-ryfG/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQkQnYzYalYqTfUfvyzJXMUOh8F5Ey37Shl4N0fChbvEUmwQRHwSoabybRyZm1imNmMYN1DqKvLffn8/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_042": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vThvHdE72__PXE9RBjjQWn0BNe7eavt2Qul7sWQkLtt6tuPshl0_qb3QP_3ZWyhR0WrMc9lHjDBK2kj/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSOGZNCyHy-UuA6PGUwK1otEUFI5vwNVJ_J61Peg7-D4Cp8dZ22W4hvqr-gMoMCbgypYgqCiL2n0DU6/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_043": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS1TKzNAsBMEbM-OB3ccLKfNoCX2JfTA86LhBlzmRa_iBDxj4p97r8-KkuF_-nNU-NztzJSpaW_tsln/embed?start=false&loop=false&delayms=1800000",
      "time": "[3 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTRYHVD-7Ip9bNnGC0dnncmHDu5xPf5Dg65tx-92UwJHuEGvEq156nGQifYc_460TU1aF3JLaVTwFkW/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_044": {
      "title": "Level 1: Concern for the other, leading to a specific 'act of service'",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQIwKAdN0I2-Q4bjniHEPe9UMBos_oVskA7-NUKnJxXd2NEXhVxwapFGzfoq1cvGlsY92SCZNfgQSjm/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTkYj8gNa5GBJfXlVasdyIIPJISo4zBnN1a-MiW-ErLZyEhPSraRLHYG-VXSIaDpXgEbf_pdQP35RI6/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_045": {
      "title": "Level 2: Taking responsibility for someone elseâ€™s goal as if it were my own",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRIVsJXy-fkBvmcdzSFceBi1nruu0NHwViJkBCZGKL_FVx1KWubGRalvM6pSk4RqwVZEDNV21D6_HtV/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRS9k2O-29p8caHmjHjWzwkI3wWGEdnF1j5mAdjZtx3iUb9hpARocnodmLFzY56fVhky2IU21hITNxN/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_046": {
      "title": "Level 3: Dedicating my whole life to the welfare of others",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS2IF-KrCKNh0KCWwTI1DpcZYtmovQCTewat227pNiMYJCZ9WTJnQIaXPWl5nH4t_HVlZgvXuj7JH1P/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRddn6pC7V7FebARvu0teRrjrNIGNtVr3IQ6MM9T_jDlPTVZxXB11HAoC8E-UgFsNy2XYYf-5279ytc/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_047": {
      "title": "Class Project [Optional]",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQkOMQ9Ch4FTtsPBmuCr30HBfb59f_oEt7nVC8lhUtLiwXm8bQbhWwPh5QsTCWMHFFWp3WMMvccXnZ3/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSfAwWGq96Ypcwr_EiZez96XU6VTQ7MTIsV5fvCqGF33dKeNRRrh0xPluK0xB2FMcNuqy2bCbNNYnar/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_048": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSzAMuO0IVzedNjHpKfYmo1xd5mjOJbwWaJGUGFG4IZyH4Dbr-Wkr2bh2_bl7dzpY-5tpO03mfJRVWZ/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQExuBabu6gUlYq9ke1LHEtfSmaaNCirdLX7gxv97YrOqRXxKTKxHM5TjtAP4-R7ir7QVQf8QKyRlGW/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_049": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTDtPMbb-W3vwiwnpQBnybzSXFTQWyd5v24jTkjVnO6JQDPbcnM9jhwmY-X9ic3vu0rfiDefxYexupp/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRsCHgafbWN7qYlMx0p5bP4dD73SDxuGHR-qr5EPM0BZEr_XJUlRyRcGA8Pgf8d4669z_qubKbkm2WY/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_050": {
      "title": "Events of concentration",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS4bc8mU17TuFoh0QR6wbRjvDDd0pBIBD8gS-hk3SFoY2hKKiIbpLe0DptO8fFAeeMrzyC7sD0GXWav/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR2jchhBJoYXCnOXhAM98KlEAz9XqDm4if8e4ZM-YO0gUUkM2Fg7FWPqTsC-JyehZOJfsZmoCuLNHnJ/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_051": {
      "title": "Zone of Concentration",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQzNhEll-yFGrCzKFMTppJ0jw4CCdYIzfGgn10AQXG1vpz9jrzX2Mi1yb248U9_vQrrQB2jWB8FpZpt/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTCcfkmCtnlM5kQ99f4U6wxYR80cc7UuMHjFIJEi-nHCzEc5YtsZiJ7XvYF4-NB8jOHhEMzS7qgNJUS/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_052": {
      "title": "What happens when we develop in this pathway of Concentration and focus?",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRtcxARvvoGwMsDu0HDEJonQApZH7ky_6gMBx6XBc6rwbxFiiiDoERlOvQqM2WLXOF5GIlBYH36eNJ7/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS-tGJyoY3-6YaW_PWf5AHhSNdVHsmPHMrOzNauOquRHf7LgJInS1xpQ_tf4YwFNTzyGjJZTT45ZGZC/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_053": {
      "title": "Class Project [optional]",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRfK5AuCAmPGRk3CzvWL6Ih9gWrK3e7kWQpiccLG6B6l0CPjtzUvfxEv5WkGxhcZ8nniSTIbPF8jhZg/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQVYPQzD_Q676XpO-scQo7A4oNSMGszwN2_kR6HVMqe9P5W4dl6A_XYGG5d3KD4CMeH36eY9ygDn5Is/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_054": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSXVrA_fWZT2RrLSIWLfN28EbGiWuLIlS7labmBnCkOesKafeRhhDLdLPd_zmAmF0ZO_0XWDTQ0piol/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vST69479mgc-7ih0Ve5xoA55cwncxwtF71pmLpXr6s_x97y7tV0BbgITcttUAmtB67b-1eNPxFU-zI2/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_055": {
      "title": "Part 1: What does it mean to practice same-sightedness",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRx_E_LN6kLQFvcVUl8HW4ZjadWRZy2NZFaTiJa7UonofunsiUJTizaTMSDTXnWFfifMZ6Xil4c_6xO/embed?start=false&loop=false&delayms=1800000",
      "time": "[45 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSeG45GH-l_JMXupVjVMyY5jVbTxCW9Xqt3hO_XIg7x9EODvK4tCw9axPOXW0Sq7R54lv7ohbXZp2o1/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_056": {
      "title": "Part 2: What does it mean to practice same-sightedness in the context of our society",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRqACFUOUQHpbMg-MILBPcjvNCITSXlOx1qZo81JXIRlXuHTCT9gadbZjrwCUZHG5cUL00zCdK6EzYN/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQCDZzEmdvfGMbFWuzcDTGSJ2zDA_oENPf7YIamjdC9YAxtWuYf9azVrkfRI51lQ3mb8XZ6c5EZNlbW/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_057": {
      "title": "Class Project [Optional]",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vShQhfgxVUf4HAc3IKcynv3KQ_Bb1wth-NR1wlEMI-kplbH-MCMIB8iK0fiGpsX7yrrtw6lpi-OF4zb/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS2DqlIOB2wRN7HRufeussQ5YgPdXZGD8uib6PAxQ4mT0BCWye_MLciTvTQ-mhaPB-AlU5xAEjqONZS/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_058": {
      "title": "What is positive engagement",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTmrUYGrmUpNmfJs6eOy1zo6X2PTepXICTiLA-0ObqmRT1xGKRMHFNLlKEpsM9-ePJEYAzgBWTdJmJ9/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR_mM7F7-wDOM9SMxwAWr8QOKgJ89iHCzOMKu14PJcVZkMdZl7Jv-jzveu-LMkJPjpwrrDxUW9-BJ4N/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_059": {
      "title": "Positive engagement with people",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR4zCMFdaR5mBMOwEe_1QLQzJXZ3hooVOIETkcZ7NAcGUoqCTQsZZb9rnoknftmQwCHeD5q9KFFHF2m/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQWrLROerr-aqL6LgdUqs7LNBSUygXnEadXlz8-6-HHpT3GbhsQIa0YODZpTNTgg6ow_jmWrLoki7dQ/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_060": {
      "title": "Positive engagement with advice/ guidance",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vS_8BlrTGWVgtvIMBgcTGKk4YskpN-xl2vOVJx30uEhHxAwxUZDG2lmYJCrOm7u1tPaj8lpFAcP3-p3/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTejw8MbOfU3yBROkVOIpdRR1pXkbIWaVyY6-gvZtimmdevIKlINBSprfYkEz0GPHE8oUrZxmQy_ynp/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_061": {
      "title": "Positive engagement with ideas",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vR9AT8TOdrCmzi8EqpA6-uu9o1sBgGeUdTy3aSuuXoKkxe8VSz1qzxWnzgGWt1gosYmydx32MjalQgQ/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTpi7W_Tl7mMvSHak7ZbhKr8gpewnqjFt890hRf1_bkHFIhu7vypTbGp2vuAxb5mvukATnvLsIn_3xl/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_062": {
      "title": "Positive engagement with outcomes",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQWos5ww6URvLA9xrHacxq01kOxYSaNFRWCJMHjtyAusPrf-yUjUMjyJhr0YD1UoYngIArQMWBflDT1/embed?start=false&loop=false&delayms=1800000",
      "time": "[15 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQGepLT7rnI3W5idvJugn0ORLTe_AAqr0mvv8b70cMQ6vzocEbp8_9-lWmJ2EloyHH2tVkQSfgIQ2Xy/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_063": {
      "title": "Class project [Optional]",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRghC8DGkOg3mOx5mX1jg0PhtHePp-eTcqlrPXhtWfyQ4mKUtNFoEd0MVoZQYYAu0hk_xoQa42QjdLh/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTWzhoIGjpWJOlX4HWoYfSZYFtI2-fXiG3nmuze4SUB1zE3zmhij_A3MG9Anfw48r-ajSJ8xSgcpC9n/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_064": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTRlC5rvU1VjY0rzFlMdjb22AzS6i0fd1O_sM6qLOin2PgAmkKQ-vOaHLon2R_jHCWGY0eO9FGO_Oc8/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSaeM5BQUCNNyiy6vKYOmFknIHqtLH1NjmPOlqF0lPTN9d8hy9gZoilSAgMk-3LAWvsDiZjHV7bHaEZ/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_065": {
      "title": "Introduction",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSVBvXCjnqChllLdByR8Gpq90NrYoDgSaEE08z6lCqFNMKYJKbDJ3xB2RD4jmxG0kK59Xugn1PJyX1T/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRDJ51JTXGYPCaKaMYIk6U1OwkqiNv4PRjNmjrh2tC2_TkVJwmMJa-KVV7Cjg6KpfVhbzrJaWgFi69E/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_066": {
      "title": "Part 1 - Choosing our ideal",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQyiK38p-nZmtf7JxpPDZCBHB-Tz0wlYpdWNtmAk3P4IUaYFFZLV47CLiVowEPI90xMOapaWxGduy4f/embed?start=false&loop=false&delayms=1800000",
      "time": "[35 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTrvg9ztxtfBN7dHK6sLTXVGrMd2NirQvoP_BFoQmeB_BLbEVWRk1TyGrq0ZeZNvIT9AttYMJQFgaaA/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_067": {
      "title": "Part 2 - Developing ourselves to become our ideal",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSSkPOFSvXSu6y3U53T3-rKWRLs8VGYCr8OdlAfjxL0DLg-sQk-9yPn42F6PXUlH2kCE2nH-Rq1BZFJ/embed?start=false&loop=false&delayms=1800000",
      "time": "[20 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRCszrUYEecT2ppH4DJCP8Pr2z_zTAgllfDyAc_KniYmWNbkNNbFtht8dHdwoap5ixwZqXpuGYHQ7Fz/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_068": {
      "title": "Summary",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vQd-ZrrUy6A3x32A7aCEoCzf7SikUdfCNgb8fBIWtKCWr5_U5xs4hAJVNzvGRbQEGxFEYBftORrupNN/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ2RorJfXExB0kVAMlYNo1BOtqUyVbtUmUFJj4gFw8cJ0KMRQh_Cne5Juioe2dPjJXoXX3LAVJ-rqz-/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_069": {
      "title": "6 Key Learnings: Key learning 1",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSath2LXtT0PFMEMYlqBU5bcidqW0s20kP-TQLB72ZgzYd3qtX8nqi67vg7XK7DAG63XLlt_PACUNeJ/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSFCCnEroG2T7iZ8ZLbN1Jfr7AQjAvqV47WFAQveEwo-4J6qLKgylv0gTpb9-Y1OIeIwvnX-21jjpwB/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_070": {
      "title": "Key learning 2",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vT4ewichZWJjv7ftyEBSXk1thu8KyeGES3-yNsbRPBi_pnOMCbtVUQEXxFeSrIGKhnPdt2p_cjQlJCU/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRwBJ3Hhl3MlG5JKRhPLInxWMb_8m_Es-6yLYe-SjGfiGq_R5-6hN5qkUFaJbDyV-xJK5cHcbJMdPri/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_071": {
      "title": "Key learning 3",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRzYxvZga6g3PV5ariz5JhoxQVZ55SEdMukAbm_NgQmI5uqVDO_N1nJXfCXcv753D2BVj_wuMed2uV5/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT_aMV4bkaYtXEJKW90JMqu9HtkBprUbYrh-JafRTl-UqUi66MgzVk2IL2jWKCtA6OAJuyzpQiEq0rw/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_072": {
      "title": "Key learning 4",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSiyJhtkyDVMPc7ZPU89Ahjmbd5o1aDjMJv5yB813K0uzZgbpCidaDiTiuGEQlds48pcEj9Fo2k11qc/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT9OtatkOtK07_IpYi4e6ia0tMQaVdfdMRzX6hXct17vJymbSunOrYLNB6J5shQcZcA4NCwP3SHSN7i/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_073": {
      "title": "Key learning 5",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vTSxFvw2NVRx-U9Dts8xL6Lj91fERNCdv9pNvDjbwuIp5GxhjZAUv97tBRnGZoqgvJTmg19neZIXDI_/embed?start=false&loop=false&delayms=1800000",
      "time": "[~10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRHedqq7zHqRJ_1nplxc37b9J_ViHL_puYXjF6GTkfHSZ6_HCHTRMCqsPc0otM7Xo7qSf1yj9CIL7SQ/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_074": {
      "title": "Key learning 6",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vRIc2fdh7baZttllbtW4vW1XL_taG3erCKIH_Diu_sDe2xNzCBRYJeTbrJzj24peX6d35oOG0HhIU7q/embed?start=false&loop=false&delayms=1800000",
      "time": "[10 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTcNILGmmAqBQr_COMon2Qfm6mJWpD7D2pIh5pD2V_US1_ITqb9atFf46L105lc-NhZDJxCQt4_zOTo/embed?start=false&loop=false&delayms=1800000"
   },
   "uo_075": {
      "title": "Conclusion",
      "type": "ppt",
      "src": "https://docs.google.com/presentation/d/e/2PACX-1vSLPn4FmJ6gtGIvX3Uy1ipDOsaNVvmrc6L-qUP6jDSUV1UVqQk0v3U0lsY5lX1g5OSXtWpNKWK6vR8h/embed?start=false&loop=false&delayms=1800000",
      "time": "[5 min]",
      "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTm6ZNWJ2lFE33POA4I1992aJ8ipaQcAo2Jml5_7fRf35JlangSpnvi0Ps5rXKlD1leZrzefkpdb5Ng/embed?start=false&loop=false&delayms=1800000"
   }
 }