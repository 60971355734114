import React from 'react';
import PropTypes from 'prop-types';
import {Link} from 'react-router-dom';
import "./MyCapsules.css";
import Button from '@material-ui/core/Button';

function Capsule(props) {

  const { enumerate } = props;
  const {
    heading,
    title,
    description,
    icon,
    route,
    ready,
    icon_bg,
    excerpt
  } = props.cardData;
  let _Route = props => <Link to={route} {...props} />
  return (
    <div className='capsule-cntr'>
      {enumerate > 0
        ? <div className="card-enumeration">{enumerate}</div>
        : null}
      <div className='capsule-blk'>
        <div className='capsule-left'>
          <div className="ch-sub-title">{heading}</div>
          <div className="ch-title">{title}</div>
          <div className="ch-description">{description}</div>
          <div className="ch-description">{excerpt}</div>
          <div className='action-cntr'>
          {ready === true?<Button component={_Route} classes={{root:"action-btn-capsule", label:"btn-text"}}>{props.buttonText}</Button>:<div className='action-btn disable'>Coming Soon</div>}
          </div>
        </div>
        <div
          className='capsule-right'
          style={{
          // backgroundColor: icon_bg
        }}>
          <img
            alt="home-img"
            src={icon
            ? icon
            : "https://s3.ap-south-1.amazonaws.com/illumineschool/homeimg1-op.jpg"}
            title="Illumine Digital"/></div>
      </div>
    </div>
  );
}

Capsule.propTypes = {
  cardData: PropTypes.object.isRequired,
  enumerate: PropTypes.number
};

export default Capsule;