import {units} from "./d_units_Y1";
const {uo_001, uo_002, uo_003, uo_004} = units
const {uo_005, uo_006, uo_007, uo_008, uo_009, uo_010} = units
const {uo_011, uo_012, uo_013, uo_014} = units
const {uo_015, uo_016, uo_017} = units
const {uo_018, uo_019, uo_020, uo_021} = units
const {uo_022, uo_023, uo_024, uo_025} = units
const {uo_026, uo_027, uo_028} = units
const {uo_029, uo_030, uo_031, uo_032} = units
const {uo_033, uo_034, uo_035, uo_036, uo_037, uo_038, uo_039} = units
const {uo_040, uo_041, uo_042, uo_043, uo_044} = units
const {uo_045, uo_046, uo_047, uo_048, uo_049, uo_050} = units
const {uo_051, uo_052, uo_053, uo_054, uo_055, uo_056, uo_057} = units
const {uo_058, uo_059, uo_060, uo_061} = units
const {uo_062, uo_063, uo_064, uo_065, uo_066} = units
const {uo_067, uo_068, uo_069, uo_070, uo_071, uo_072, uo_073, uo_074, uo_075, uo_076} = units
const {uo_077, uo_078, uo_079} = units
export const organisers = {
  "os_001": {
    preTest: "",
    postTets: "",
    units : {uo_001, uo_002, uo_003, uo_004}
  },"os_002": {
    preTest: "",
    postTets: "",
    units : {uo_005, uo_006, uo_007, uo_008, uo_009, uo_010}
  },"os_003": {
    preTest: "",
    postTets: "",
    units : {uo_011, uo_012, uo_013, uo_014}
  },"os_004": {
    preTest: "",
    postTets: "",
    units : {uo_015, uo_016, uo_017}
  },"os_005": {
    preTest: "",
    postTets: "",
    units : {uo_018, uo_019, uo_020, uo_021}
  },"os_006": {
    preTest: "",
    postTets: "",
    units : {uo_022, uo_023, uo_024, uo_025}
 },"os_007": {
    preTest: "",
    postTets: "",
    units : {uo_026, uo_027, uo_028}
  },"os_008": {
    preTest: "",
    postTets: "",
    units : {uo_029, uo_030, uo_031, uo_032}
  },"os_009": {
    preTest: "",
    postTets: "",
    units : {uo_033, uo_034, uo_035, uo_036, uo_037, uo_038, uo_039}
  },"os_010": {
    preTest: "",
    postTets: "",
    units : {uo_040, uo_041, uo_042, uo_043, uo_044}
  },"os_011": {
    preTest: "",
    postTets: "",
    units : {uo_045, uo_046, uo_047, uo_048, uo_049, uo_050}
  },"os_012": {
    preTest: "",
    postTets: "",
    units : {uo_051, uo_052, uo_053, uo_054, uo_055, uo_056, uo_057}
  },"os_013": {
    preTest: "",
    postTets: "",
    units : {uo_058, uo_059, uo_060, uo_061}
 },"os_014": {
    preTest: "",
    postTets: "",
    units : {uo_062, uo_063, uo_064, uo_065, uo_066}
  },"os_015": {
    preTest: "",
    postTets: "",
    units : {uo_067, uo_068, uo_069, uo_070, uo_071, uo_072, uo_073, uo_074, uo_075, uo_076}
  },"os_016": {
    preTest: "",
    postTets: "",
    units : {uo_077, uo_078, uo_079}
  }
}