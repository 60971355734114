import React, {Component} from 'react';
import {connect} from "react-redux";
import {withRouter} from "react-router-dom";
import Header from "../../../components/Header";
import channelData from "../../../modals/d_channels_Y3";
import {subscribeAProduct} from "./redux_actions";
import Footer from "../../../components/Footer";
import "./store.css";
import {Link} from "react-router-dom";
let storeDashboard = class storeDashboard extends Component {
  shouldComponentUpdate(a, b) {
    return true;
  }
  render() {
    let userState = this.props.userState;
    let myAccount = userState.myAccount;
    let subscribedChannels = {};
      Object.keys(userState.mySubscriptions)
      .forEach(el => {
        subscribedChannels[el] = true;
      });

    return <div style={{
      width: '100%'
    }}>
      <Header active='catalouge'/>
      <div className='store-cntr'>
        <div className='-store-cntr-row'>{populateChannels(subscribedChannels, myAccount)}</div>
      </div>
      <Footer/>
    </div>
  }
}

function populateChannels(subscribedChannels, myAccount) {
  console.log(subscribedChannels);
  return <div>
    <div>
      <div className="section-title bold">Welcome to the Illumine Awaken! Catalogue</div>
      <div className="section-description">Choose a Capsule you want to run with your child</div>
      <div className="store-cntr-row">{Object
          .keys(channelData)
          .map(key => {
            let channel = channelData[key];
            let subscribed = subscribedChannels[key] || false;
            let ready = channel.ready || false;
            return ready
              ? channelCard(channel, key, subscribed, ready, myAccount)
              : null;
          })}</div>
    </div>
    <br />
    <div>
      <div className="section-title bold">Discover upcoming Capsules</div>
      <div className="section-description">New ways in which you can enable your child through the Illumine Awaken! Platform. Coming Soon!</div>
      <div className="store-cntr-row">{Object
          .keys(channelData)
          .map(key => {
            let channel = channelData[key];
            let subscribed = subscribedChannels[key] || false;
            let ready = channel.ready || false;
            return ready
              ? null
              : channelCard(channel, key, subscribed, ready, myAccount);
          })}</div>
    </div>
  </div>
}

function channelCard(channel, key, subscribed, ready, myAccount) {
  return <div key={key} className='store-product-card'>
    <div
      className="icon-cntr"
      style={{
      backgroundColor: channel.icon_bg
    }}><img alt="channel-icon" src={channel.icon}/></div>
    <div className="bold channel-title">{channel.title}</div>
    {subscribed
      ? <div className="subscribed-btn">Subscribed</div>
      : null}
    <div className="overlay">
      <div className="product-card-back">
        <div className="product-card-back-content">
          <div className='-title bold'>{channel.title}</div>
          <div className='-desc'>{channel.description}</div>
        </div>
        <div className="action-cntr">
          {subscribed
            ? <div className="action-btn disabled">Subscribed</div>
            : ready
              ? <div
                  className="action-btn active"
                  onClick={() => {
                  subscribeAProduct(key, myAccount, channel.cost||250)
                }}>{channel.cost||250} INR</div>
              : null}
          <Link to={'/application/explore/' + key}>
            <div className="action-btn-text">{ready?"Know More":"Discover"}</div>
          </Link>
        </div>
      </div>
    </div>
  </div>
}

const mapStateToProps = state => ({userState: state.userState, auth: state.auth});

export default withRouter(connect(mapStateToProps, null)(storeDashboard));