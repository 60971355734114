// import {organisers} from "./d_organisers_Y3";
import React from "react";
let mainRoute = "/application/unit";
// https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/Year01_ACP-FacTool-FOR+READING.PDF
const channels = {
  ch001:{
    heading: "Quick Guide",
    title:"Practices to keep in mind as a facilitator",
    excerpt:<div>Practices to keep in mind as a facilitator</div>,
    icon: "./images/assets/session-icons/practices--keep-in-mind.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/Facilitation+Practices+Handout+-1.pdf",
    docRatio: 0.77229
  },
  ch002:{
    heading: "Class Reference Tool",
    title:"Class Reference Tool for Year 1",
    excerpt:<div>This is a consolidated quick reference tool for the trained ACP facilitators to conduct Year 1 of the program. It contains "tips for effective execution" for each of the learning units in every session. </div>,
    icon: "./images/assets/session-icons/fac-tool-1.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/Year01_ACP-FacTool-FOR+READING.PDF",
    docRatio: 1.475
  },
  ch003:{
    heading: "Class Reference Tool",
    title:"Class Reference Tool for Year 2",
    excerpt:<div>This is a consolidated quick reference tool for the trained ACP facilitators to conduct Year 2 of the program. It contains "tips for effective execution" for each of the learning explorations in every session.</div>,
    icon: "./images/assets/session-icons/fac-tool-2.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/Year02_ACP-FacTool-FOR+READING.PDF",
    docRatio: 1.475
  },
  ch004:{
    heading: "Class Reference Tool",
    title:"Class Reference Tool for Year 3",
    excerpt:<div>This is a consolidated quick reference tool for the trained ACP facilitators to conduct Year 3 of the program. It contains "tips for effective execution" for each of the learning explorations in every session.</div>,
    icon: "./images/assets/session-icons/fac-tool-3.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/Year03_ACP-FacTool-FOR+READING.PDF",
    docRatio: 1.475
  },
  ch005:{
    heading: "Class Reference Tool",
    title:"Class Reference Tool for Year 1 – HINDI VERSION",
    excerpt:<div>This is a consolidated quick reference tool for the trained ACP facilitators to conduct Year 3 of the program. It contains "tips for effective execution" for each of the learning explorations in every session.</div>,
    icon: "./images/assets/session-icons/fac-tool-1.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/ACP-FacTool-Year_01_Hindi.pdf",
    docRatio: 1.475
  },
  ch006:{
    heading: "Class Reference Tool",
    title:"Class Reference Tool for Year 2 – HINDI VERSION",
    excerpt:<div>This is a consolidated quick reference tool for the trained ACP facilitators to conduct Year 3 of the program. It contains "tips for effective execution" for each of the learning explorations in every session.</div>,
    icon: "./images/assets/session-icons/fac-tool-2.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/ACP-FacTool-Year_02_Hindi_v2_with+cover.pdf",
    docRatio: 1.475
  },
  ch007:{
    heading: "Class Reference Tool",
    title:"Class Reference Tool for Year 3 – HINDI VERSION",
    excerpt:<div>This is a consolidated quick reference tool for the trained ACP facilitators to conduct Year 3 of the program. It contains "tips for effective execution" for each of the learning explorations in every session.</div>,
    icon: "./images/assets/session-icons/fac-tool-3.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    type: "pdf",
    src: "https://acp-illumine.s3.ap-south-1.amazonaws.com/fac-guides/ACP-FacTool-Year_03_Hindi_with+cover.pdf",
    docRatio: 1.475
  }
};

Object.keys(channels).forEach((key, i)=>{
  channels[key].route = mainRoute+"/"+key;
});

export default channels;