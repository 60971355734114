import {units} from "./d_units_Y2";
const {uo_001, uo_002, uo_003, uo_004, uo_005, uo_006, uo_007} = units
const {uo_008, uo_009, uo_010} = units
const {uo_011, uo_012, uo_013} = units
const {uo_014, uo_015, uo_016} = units
const {uo_017, uo_018, uo_019, uo_020, uo_021, uo_022, uo_023} = units
const {uo_024, uo_025, uo_026} = units
const {uo_027, uo_028, uo_029} = units
const {uo_030, uo_031, uo_032, uo_033} = units
const {uo_034, uo_035, uo_036} = units
const {uo_037, uo_038, uo_039} = units
const {uo_040, uo_041, uo_042, uo_043} = units
const {uo_044, uo_045, uo_046, uo_047} = units
const {uo_048, uo_049, uo_050, uo_051, uo_052, uo_053, uo_054} = units
const {uo_055, uo_056, uo_057} = units
const {uo_058, uo_059, uo_060, uo_061, uo_062, uo_063, uo_064} = units
const {uo_065} = units

export const organisers = {
  "os_001": {
    preTest: "",
    postTets: "",
    units : {uo_001, uo_002, uo_003, uo_004, uo_005, uo_006, uo_007}
  },"os_002": {
    preTest: "",
    postTets: "",
    units : {uo_008, uo_009, uo_010}
  },"os_003": {
    preTest: "",
    postTets: "",
    units : {uo_011, uo_012, uo_013}
  },"os_004": {
    preTest: "",
    postTets: "",
    units : {uo_014, uo_015, uo_016}
  },"os_005": {
    preTest: "",
    postTets: "",
    units : {uo_017, uo_018, uo_019, uo_020, uo_021, uo_022, uo_023}
  },"os_006": {
    preTest: "",
    postTets: "",
    units : {uo_024, uo_025, uo_026}
 },"os_007": {
    preTest: "",
    postTets: "",
    units : {uo_027, uo_028, uo_029}
  },"os_008": {
    preTest: "",
    postTets: "",
    units : {uo_030, uo_031, uo_032, uo_033}
  },"os_009": {
    preTest: "",
    postTets: "",
    units : {uo_034, uo_035, uo_036}
  },"os_010": {
    preTest: "",
    postTets: "",
    units : {uo_037, uo_038, uo_039}
  },"os_011": {
    preTest: "",
    postTets: "",
    units : {uo_040, uo_041, uo_042, uo_043}
  },"os_012": {
    preTest: "",
    postTets: "",
    units : {uo_044, uo_045, uo_046, uo_047}
  },"os_013": {
    preTest: "",
    postTets: "",
    units : {uo_048, uo_049, uo_050, uo_051, uo_052, uo_053, uo_054}
  },"os_014": {
    preTest: "",
    postTets: "",
    units : {uo_055, uo_056, uo_057}
 },"os_015": {
    preTest: "",
    postTets: "",
    units : {uo_058, uo_059, uo_060, uo_061, uo_062, uo_063, uo_064}
  },"os_016": {
    preTest: "",
    postTets: "",
    units : {uo_065}
  }
}