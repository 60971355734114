import {organisers} from "./d_organisers_Y1";
import React from "react";
let mainRoute = "/application/channel/Y1";

const channels = {
  Y1Session1:{
    heading: "Session 1",
    title:"Developing faith in our infinite possibilities",
    cost: 250,
    excerpt:<div>Students get introduced to the goals of the Awakened Citizen Program and discover 4 ways to awaken their infinite inner power lying dormant within them, which can increase their faith in themselves (i.e. their inner power). <br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon: "./images/assets/session-icons/year1-session1-icon.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_001,
    poster: null,
    teaser: null,
    offering: null
  },Y1Session2:{
    heading:"Session 2",
    title: "The human possibilities open to us",
    cost: 450,
    excerpt: <div>Students review the key idea of the first session and get introduced to the 2 types of human possibilities open to us: (i) our universal possibilities and (ii) our unique possibilities.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session2-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_002,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y1Session3:{
    heading:"Session 3",
    title: "Being Heroic",
    cost: 250,
    excerpt: <div>Students discover how each of us can awaken our universal possibility of ‘being heroic’, in our everyday life. <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session3-icon.jpg",
    icon_bg: '#87D6A7',
    organisers: organisers.os_003,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y1Session4:{
    heading:"Session 4",
    title: "Expanding Myself",
    excerpt: <div>Students discover three ways in which each of us can awaken the possibility of expanding ourselves.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session4-icon.jpg",
    icon_bg: '#F4DF84',
    organisers: organisers.os_004,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y1Session5:{
    heading:"Session 5",
    title: "Living in Harmony",
    excerpt: <div>Students discover how each of us can awaken this possibility of living in harmony.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session5-icon.jpg",
    icon_bg: '#FFCE94',
    organisers: organisers.os_005,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y1Session6:{
    heading:"Session 6",
    title: "Seeking Perfection",
    excerpt: <div>Students explore 4 different aspects of our life in which we can pursue excellence or seek perfection and discover how each of us can awaken this possibility in our day-to-day life.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session6-icon.jpg",
    icon_bg: '#EDCCFF',
    organisers: organisers.os_006,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y1Session7:{
    heading:"Session 7",
    title: "Recognizing the sacred (Developing shraddha)",
    excerpt: <div>Students discover what it means to unfold the possibility of recognizing the sacred (developing shraddha) in two areas – developing shraddha in our cultural heritage and traditions & developing shraddha in our nationhood and democratic traditions of India.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session7-icon.jpg",
    icon_bg: '#E2DF7D',
    organisers: organisers.os_007,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y1Session8:{
    heading:"Session 8",
    title: "Being a truth-seeker",
    excerpt: <div>Students discover different ways in which we can awaken our truthseeking spirit.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session8-icon.jpg",
    icon_bg: '#B4F7D1',
    organisers: organisers.os_008,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y1Session9:{
    heading:"Session 9",
    title: "Review of our universal possibilities",
    excerpt: <div>Students review all the universal possibilities that they’ve explored so far.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session9-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_009,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session10:{
    heading:"Session 10",
    title: "Part A: Playing games and sports | Part B: Adapting to the environment I live in",
    excerpt: <div>In Part A, students explore our physical possibility of playing games and sports and discover how our personality develops and transforms through this possibility. In Part B, they explore the possibility of adapting to the environment we live in and discover how several of our qualities and capacities that lie dormant within us get awakened through it.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session10-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_010,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session11:{
    heading:"Session 11",
    title: "Part A: Expressing myself | Part B: Engaging with culture and society",
    excerpt: <div>In Part A, students explore the possibility of engaging with culture and society and discover the qualities and capabilities they develop because of it. In Part B, they discover our physical possibility of ‘creating value’ and discover the capabilities we develop because of it.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session11-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_011,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session12:{
    heading:"Session 12",
    title: "Part A: Creating Value | Part B: Expanding the limits of my world",
    excerpt: <div>In Part A, students discover our physical possibility of ‘creating value’ and discover the capabilities we develop because of it. In Part B, they explore the possibility of expanding the limits of my world and discover the qualities and capacities that they can develop because of it.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session12-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_012,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session13:{
    heading:"Session 13",
    title: "Part A: Realizing a creative vision | Part B: Working with collective intelligence",
    excerpt: <div>In Part A, students explore our knowledge possibility of ‘realizing a creative vision’. In Part B, they explore the possibility of ‘working with collective intelligence’.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session13-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_013,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session14:{
    heading:"Session 14",
    title: "Part A: Inventing new things | Part B: Developing solutions to complex social challenges",
    excerpt: <div>In Part A, students explore the possibility of ‘Inventing new things’. In Part B, they explore the possibility of developing solutions to complex social challenges.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session14-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_014,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session15:{
    heading:"Session 15",
    title: "Part A: Learning in all parts of my life | Part B: Extending the limits of human knowledge",
    excerpt: <div>In Part A, students explore the knowledge possibility of ‘learning in all parts of my life’ and learn 6 ways to awaken this possibility in themselves. In Part B, they explore the knowledge possibility of ‘extending the limits of human knowledge’.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session15-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_015,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y1Session16:{
    heading:"Session 16",
    title: "Summary of our unique possibilities",
    excerpt: <div>Students review all the unique possibilities that they’ve explored so far and conclude their key learnings out of this year of the Awakened Citizen Program.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year1-session16-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_016,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  }
};

Object.keys(channels).forEach((key, i)=>{
  channels[key].route = mainRoute+"/"+key;
});

export default channels;