import firebase from "firebase/app";
import "firebase/messaging";
import "firebase/auth";
import "firebase/database";
import 'firebase/analytics';
// import store from "../../Redux_Store";
if (!firebase.apps.length) {
  const config = {
    apiKey: "AIzaSyCAIaTCYR0InKoRO3q4NiACcYOlC0a1w1Q",
    authDomain: "acp-illumine.firebaseapp.com",
    databaseURL: "https://acp-illumine.firebaseio.com",
    projectId: "acp-illumine",
    storageBucket: "acp-illumine.appspot.com",
    messagingSenderId: "715521075500",
    appId: "1:715521075500:web:886098fdfa0274638e6489",
    measurementId: "G-QC0K6DNW24"
  };
  firebase.initializeApp(config);
  firebase.analytics();
}


export const watchMySubscriptions = (uid) => {
  var mySubscriptionRef = firebase.database().ref(`users/${uid}/mySubscriptions`);
  mySubscriptionRef.on('value', function (snapshot) {
    // console.log(snapshot.val());
  });
};