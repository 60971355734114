import {organisers} from "./d_organisers_Y2";
import React from "react";
let mainRoute = "/application/channel/Y2";

const channels = {
  Y2Session1:{
    heading: "Session 1",
    title:"The Introduction",
    cost: 250,
    excerpt:<div>Students discover how unfolding one’s universal possibilities (the focus of Year 2) is the foundation of a great character & personality and has the power to transform one’s personality and contribution in the world. <br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon: "./images/assets/session-icons/year2-session1-icon.jpg",
    icon_bg: '#F4D46E',
    ready: true,
    organisers: organisers.os_001,
    poster: null,
    teaser: null,
    offering: null
  },Y2Session2:{
    heading:"Session 2",
    title: "Developing Atmashraddha - Part 1 & 2",
    cost: 450,
    excerpt: <div>In Part 1, explore 4 different ways to develop openness to learning which in turn leads to strengthening of our atmashraddha; and in Part 2, learn how “continuously improving oneself in whatever we do” helps us increase our Atmashraddha or self-confidence over time.<br /><span className="bold" style={{marginTop:"1rem",  display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session2-icon.jpg",
    icon_bg: '#7FDDDA',
    organisers: organisers.os_002,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y2Session3:{
    heading:"Session 3",
    title: "Developing Atmashraddha - Part 3 & 4",
    cost: 250,
    excerpt: <div>In Part 3, students discover how willingness to face challenges helps increase Atmashraddha over time. In Part 4, they explore taking on bigger and bigger goals & accomplishing them as a pathway to build their own atmashraddha. <br /><span className="bold" style={{marginTop:"1rem", display:"block"}}></span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session3-icon.jpg",
    icon_bg: '#87D6A7',
    organisers: organisers.os_003,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y2Session4:{
    heading:"Session 4",
    title: "Being Heroic - Part 1 & 2",
    excerpt: <div>In Part 1, students explore ‘facing challenges courageously’ – the first form of heroism we can develop within ourselves. They recognize the three forms of courage and how we can act with courage in our day-to-day lives. In Part 2, they explore different ways in which the heroic spirit is awakened when
we choose to act with honesty & integrity, irrespective of consequences.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session4-icon.jpg",
    icon_bg: '#F4DF84',
    organisers: organisers.os_004,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y2Session5:{
    heading:"Session 5",
    title: "Being Heroic - Part 3 & 4",
    excerpt: <div>In Part 3, students explore how keeping commitments awakens their heroic spirit within. In Part 4, they learn how ‘choosing to obey’ can also be a heroic choice & recognize the distinction between ‘being forced to obey’ & ‘choosing to obey’.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session5-icon.jpg",
    icon_bg: '#FFCE94',
    organisers: organisers.os_005,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y2Session6:{
    heading:"Session 6",
    title: "Expanding Myself - Part 1 & 2",
    excerpt: <div>In Part 1, students learn how to widen their zone of concerns, through a case example relevant to school life. In Part 2, they learn how to think of the wider impact of their actions, through 1 or 2 case examples.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session6-icon.jpg",
    icon_bg: '#EDCCFF',
    organisers: organisers.os_006,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y2Session7:{
    heading:"Session 7",
    title: "Expanding Myself - Part 3 & 4",
    excerpt: <div>In Part 3, Students learn how to consciously expand themselves by making more and more unselfish choices in any situation they face on a day-to-day basis. This is done through 1 or 2 case examples. In Part 4, they explore 3 alternative approaches or ways of working in teams or
multiple groups and discover the most inclusive approach that can help us expand ourselves.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session7-icon.jpg",
    icon_bg: '#E2DF7D',
    organisers: organisers.os_007,
    poster: null,
    teaser: null,
    ready: true,
    offering: null
  },Y2Session8:{
    heading:"Session 8",
    title: "Living in Harmony - Part 1 & 2",
    excerpt: <div>In Part 1, students discover how harmony can be created by focusing on our similarities and accepting our differences in the context of family, community & nation, through 1 or 2 cases. In Part 2, they learn 3 ways to develop ‘respect for the people around us’ that
will help us build harmony with others.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session8-icon.jpg",
    icon_bg: '#B4F7D1',
    organisers: organisers.os_008,
    poster: null,
    teaser: null,
    ready:  true,
    offering: null
  },Y2Session9:{
    heading:"Session 9",
    title: "Living in Harmony - Part 3 & 4",
    excerpt: <div>In Part 3, students learn to see things from other people’s point of view to awaken concern for others so that they seek to do something concrete to demonstrate concern & live in harmony. In Part 4, students learn to build harmony in a collective, by sharing in the responsibilities of collectives we are a part of (team, community & family)
& how it leads to harmony.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session9-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_009,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session10:{
    heading:"Session 10",
    title: "Seeking Perfection - Part 1 & 2",
    excerpt: <div>In Part 1, students learn what is involved in seeking perfection in making a product such as an assignment. They also explore the effects of such an approach in any product they make. In Part 2, students explore 3 different areas in which we can follow the ‘right
method’ to seek perfection.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session10-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_010,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session11:{
    heading:"Session 11",
    title: "Seeking Perfection - Part 3 & 4",
    excerpt: <div>In Part 3, students learn how to seek perfection in our interactions with others, by recognizing what is ‘appropriate’ behavior in a particular context. In Part 4, they recognize how they can seek perfection in the different roles they play, by going deeper into 1 or 2 case examples of roles (as a friend) & (as a student). If time is short, do any one case example (Exp 2 or Exp 3).<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session11-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_011,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session12:{
    heading:"Session 12",
    title: "Developing Shraddha (Recognizing the Sacred) - Part 1",
    excerpt: <div>Enabling students to learn how to systematically build in themselves a sense of respect and pride in Indian culture & traditions (and that of other countries), through a series of 3 explorations.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session12-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_012,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session13:{
    heading:"Session 13",
    title: "Developing Shraddha (Recognizing the Sacred) - Part 2",
    excerpt: <div>Students learn 6 simple practices that will help them develop shraddha in our nationhood and democratic traditions.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session13-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_013,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session14:{
    heading:"Session 14",
    title: "Being a Truth-seeker - Part 1 & 2",
    excerpt: <div>In Part 1, students appreciate 3 methods that can help each of us develop a scientific mode of thinking in our approach to any situation. In Part 2, they appreciate 4 ways that can help each of us seek truth in the way
we react to people and situations.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session14-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_014,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session15:{
    heading:"Session 15",
    title: "Being a Truth-seeker - Part 3 & 4",
    excerpt: <div>In Part 3, students appreciate what it means to “seek truth in society” & it’s importance in our lives i.e. how by actively participating in truth-seeking as citizens, we can help keep corruption and malpractice in society under control. In Part 4, they appreciate “facing reality in oneself” as a method by which we can seek truth within ourselves and develop our self-awareness as human beings. They explore 3 distinct ways of thinking & responding to everyday
life experiences, that will help them be a truth-seeker in their own lives.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session15-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_015,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  },Y2Session16:{
    heading:"Session 16",
    title: "Awakening our Possibilities",
    excerpt: <div>Students consolidate their learnings of the previous sessions by exploring the life incidents of a personality who had developed all the 6 Universal Possibilities, namely, Swami Vivekananda.<br /><span className="bold" style={{marginTop:"1rem", display:"block"}}> </span></div>,
    description: null,
    badges: null,
    icon:"./images/assets/session-icons/year2-session16-icon.jpg",
    icon_bg: '#9BE589',
    organisers: organisers.os_016,
    poster:null,
    teaser: null,
    ready:  true,
    offering:null
  }
};


Object.keys(channels).forEach((key, i)=>{
  channels[key].route = mainRoute+"/"+key;
});

export default channels;