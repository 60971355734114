// import overview_faceTheBrute from "../Tools/overview_faceTheBrute";
// import overview_sameSightedness from "../Tools/overview_sameSightedness";

// let {posts_001, posts_002} = posts;

export const units = {
  "uo_001": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTUg82YN-8zr4sad92VUBXOwraFvoQMdHNNAZOTktIJGqgawLszwrfOZMpiSbKbk-acq1-t-joZSCOa/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTwUC7LTUVI3NcKiAIXrWrXPU2hbKW-bqMVVE0f97pIGZeiuik70360sfJIKMNb-mdXYIivMAmh_yJu/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_002": {
     "title": "Some case studies of great Indians - i) Jamsetji Tata",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQS0ORdIgdBNwkqP12dVf4vGePuSCnEd0tLeB437OZdoKsa05sifiPBtPKqWHmBuh9tV_qansqqEKFO/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS1aui_-oVJIKtqYusEI7WowCGKy2Tkl4cCnOBCVxIuombBr6oMSx2QanNRoCkwcswfpzlbqLvWeUt6/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_003": {
     "title": "ii) Dr. M.S. Swaminathan",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQw0h3gNVb_K16IUzO56ZVcLcbPhWsYDJ1YFfFqD2xprrIVIJOucH6-jZzYVM2uvMpC3YqTqf__nIcM/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT6wM5bRwYwnwNwsML-W27pyER8nqfO3gQ7bQ5JTNLGM2GxdEVNBjCliM1r3w-4Sdw3DPJV5frfDS8s/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_004": {
     "title": "iii) Verghese Kurien",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRLT90vFtuuRdxb8xn8QdTWXweoD6mkJ8gxt0SDWYRe1jLs8v96CKUb9bqEZoRuzzR4CN44auyTu7w7/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ1-2yOYeI-cgpZgTyx3mgl02-C9qZV6qyVhH6K-3-Wf_DexkRb5mTkm8TgQRLiH_rFddy8fl-H5nhm/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_005": {
     "title": "iv) Sarojini Naidu",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSaqGdEUnY8wD1sIhs3jll7VD5ospPKcOVC_AQIb_S0y1xvhioMf_ndFaUh7MHvjSA5PfiICRwES2rf/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQnABvMOz_yujQhNDNbdIZoFbJPZvkMCPtCtOcDx9KLpWmcqkPe8E1_KYpY8_zO2sy6--tYL-TJYQJN/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_006": {
     "title": "v) Mahatma Gandhi",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSeA7dMRH7mMPFXxrYWuu8TcBa7aSqEigraAF60z8R5iYNqFC8ZhNsJKDr1si0lL8548Hpj2yZKfFpr/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTD5bao4yLjfokKszLPoOwAo2btQ82MIMoRj6k-RvxHypvP2bj4J8008pDQqPFfipIM_M75v0paOIHv/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_007": {
     "title": "Summary",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRPHX3q6vR5Yat7TyvVjSuq_XBYfyi9GWDfTjxRoLOQKYZsUc_knKfslcKiAYBOLPPOZbNQsnN1c6O7/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQSmJPP51KggZlw2xKcprSk0a1-b3DUxDHVC9r_ddyDysuIZak-odNdE00Lgr1_ZhQiYY8ynySmheGk/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_008": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQaOaWpIlNsaI-kOw59_rCZShvH6koyFEv7tjjkwiayNcG6lLBtcyiO5Kpg2U17MiBlHqJhWGWMewwr/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRtJQfOR9hu6vRGXjTr8IS6zophbX3zbTS4ModBUooABvdV05p-be8wiHETeLYV3hkRL67xw5JfXzbB/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_009": {
     "title": "Part 1 - Openness to learning ",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQBHgX6ySwHnkwbgeY-xoXLop-ESwHL7lgLctkLjR3fgTpCfruVeomJ-APJLlMuRTZFlIRHOlzzXs-d/embed?start=false&loop=false&delayms=1800000",
     "time": "[20 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRd4c-3i6R8T95aNw-Dw529PYezRz-yGh6Y4YRuSJzWdVqHjurQ14-fifnX6aSfTsqoZGp9rMyNZJen/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_010": {
     "title": "Part 2 - Continuously improving in whatever we do ",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQrFcKZng4OzqSmuq-zP6mJSyNlbbIMOwt4XKDb6paHkQt_-kPFL7St6hsDSrpOGMsdXmX1TJNNHOmM/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTR6w-vQLeaZjfmCf8Z0f-vWEDv3K4lEh1STOEfbankDivQhQ5R75dFuQKwV80XOZoe1POCLv46EzEA/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_011": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vR-9-S7XW3Ru9DsbU6WsD2jbNyK0sF91xPcL7Ah_SiTI8My3u4MAiv6b5lWgLj3qbunUoz4ktMEihav/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSxss3SSPJAuQSfnH5kvy8mOd0ri8yYseZtquxfITT9i_6ashcZUzF_IrDZYx9WWC_k3YMyqlWujHXc/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_012": {
     "title": "Part 3 - Willingness to take up challenges ",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTIpIX0_Xa5kM7RlMjW1P0OCk2xvxM_FY7kY8dowZOVOBAmFHumFgV6eGt5pZkYBMLF8hIAM4n2Auzu/embed?start=false&loop=false&delayms=1800000",
     "time": "[20 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS4rKf_ZRdt89CjMRi8SWorIXaOdNGIJa4FcdzEomc40NfaPbIlfzNmWHJx-440DK9T2pjm39g24-Ni/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_013": {
     "title": "Part 4 - Taking up big goals and accomplishing them",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRyIk4ssSd164-7VmrfavmvKfKMIGqHJz2_Xw-xS9VU8aIK6zsEF4p1rra3rm_1-lLa8HYmlmQpOctE/embed?start=false&loop=false&delayms=1800000",
     "time": "[15 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSx_fR82vE5j3-2_Ed7YkMImOCb8b6N_m90AaFa4HQsZmopLTxsjh4E4dIxSsStWostdP8Vku2-4Bmr/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_014": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRo2dWMKsCecvW8gdY6lpKOOGNS2R-eOhRBZmRF7Y6ASceaB6lUwZj1BGH11MqYu2rllADgbVifROxw/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTfEzZ4FG-7hq_cVwd7rgZP-GlqYMDQeUynOxl2owSdffukcMIjy0pbAqtiUZ8GxZCcHHszWL3YvOa8/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_015": {
     "title": "Part 1 - Facing challenges courageously",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRDgzMu--GZmUvMFOucbITqTsrImBchhMbwpdeRXUf4uLD7JwwzKMA8wfa1N8hcorlG3VgHMN8Qo5HH/embed?start=false&loop=false&delayms=1800000",
     "time": "[20 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQI4JeVr0XMf8ypeYhfvWOFVza9MR23Mbp4iXk_9nhe4b1AVVi5WExJnvFuHwW_tviiwcvXoH18QvRg/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_016": {
     "title": "Part 2 - Acting with honesty and integrity",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQWSIa6WDnTnHAqKbLj8TU7GP_aG8FsP7Hs277co7wdTOQj2sJGwebVtCoKIAoehyJZEYqsjv4QOvVP/embed?start=false&loop=false&delayms=1800000",
     "time": "[35 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTPFn9I3xHgf5Zou0YxCU6Fel0IqVmrZKbIqa-ny-tEUdj9b5MJBfM6gosfBMWsT6A48Ca0KfwUuoYf/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_017": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQbbzLZ3KKoxOg00qgREFIgKAMmQrKxAuRYPpWGk-fLLMrxgxYsYGH6f26A6VUBQ76q_OkTPwjIsWgi/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRo2OnFc9Pnww38V236RwhyKS9LBx1QVYkU1D8kAggZItL3Ix_WDpLUQLiEAYe8CYsXW6MxcV2K_rN3/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_018": {
     "title": "Part 3 - Keeping the commitments I make",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ78o_6SczKaeynf0DxYtJVbNdIEKdj0SZXcjH3JDgIZzqXc7skMJMu6VdUmnLvUL9vMTZOOb-4ZsVF/embed?start=false&loop=false&delayms=1800000",
     "time": "[15 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSBAXYEPpO-tb7nofMjOMcW7fbsj8UPdfxip6rbYRkZ0F8X6alUW8ix7o7_5s8MlJH7QIsmS21-xK9o/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_019": {
     "title": "(Part 3 Cont.) What happens when we choose to keep our commitments or not keep them?",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQdVxnnF3VXehFNw3wCCqBS55XaVZOWRy4FmaIH-ajYwyFEKSss2TYEQ9aGha6nFqiSSONNHb1Csdv2/embed?start=false&loop=false&delayms=1800000",
     "time": "[15 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRqMAI8RmX_JKKeSFG6COej4p58LIOlC34zNo7XYM4YK9shjnsZGBRbQo8ou3dmkpVCmhfNZz4jpo61/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_020": {
     "title": "Part 3 - Summary",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQpEzLhaJKh-SJtHt7K4J9beCMYx28AkhTKn-sMCLCA25A-CQCH41u1XMopuJFh3Rb-1g4P3YUg9MdP/embed?start=false&loop=false&delayms=1800000",
     "time": "[2 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT3Bl0d8ghl4yEg5IS2LL5P5bu17iZHKNKchI8Pe4D-9VSPbIJ5e6ZFJArMnJ3Mqyi6NkUwBN60zyK-/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_021": {
     "title": "Part 4 - Choosing to obey",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTA2xTYNtojgTv2j0KdRMJW5FoMMpn0jU_Chp-ufJvgZMI0x_H9Jyd7aSjD0z1KUIdUUziidDlTV2Uh/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTt8s9YGF0pR0Sqp81Tbh38F0TzynO3s7EODmr8YWS6Zu1Cb0wamj3vLhKNVmJCE_ZyjaDC-MKjiXCX/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_022": {
     "title": "(Part 4 Cont.) Application Quiz",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSh_HN8jZXad2IQpi-R0c9mO3Zgi5U7RUvnLNaoQo0qZ12lnuBA2wb008EJ9gsYgRUQXRSwyKic86vM/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRisL-SkEBHUUTIm7F-L4W_BNf8nS0eOmtBquI8Kqm8gliJrL0bZfcc7CZkrXaQZ7Wdxdz1aNDKrwn_/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_023": {
     "title": "Part 4 - Summary",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTijn3DLHTJmQc0K-Q1lzT4Rtu3VRSP7So3a2MKuhne2inUvuYUsFfRxvZ8XWF0jgtQA5eNlbaLDcnT/embed?start=false&loop=false&delayms=1800000",
     "time": "[2 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTHpo4-UkqR7inbXWR-e4adWka6VZJvAcCp6622oYw0Hj0quZjEL7EPT73viDklNhTF-pFF0KsFNBM3/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_024": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTVAlAOFfAuaEQMXIopj2EibBlMUSXHckGqawsPpuToU3X1J8-KrRFGXWnqcx2-OIph9SKtoBcfMUPp/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT7A-z37LdbIdKFz5Ds4gclytLIFuA3SfDBTjHlr3tA2Fzdagk_KDs-KpZj3zkYUV_j8Mhz655eFUvG/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_025": {
     "title": "Part 1 - Widening my concerns",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSKRcBF6Ef0S0OsO2vIpgape8LWvYSweaOFpRs9cfnv1TDlZmLh5zm4fduzMnaz34kLeX6XlD0iOoVI/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS1y4ubzMelg4gCc8eGvOm8HqymNm0wd7W11OvCq6BO6Ru1n3Wku4-0TMmvpAqTMzkIggBGsgakczhq/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_026": {
     "title": "Part 2 - Thinking about the wider impact of my actions",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRUnhbl0lfdI0HWVwVAZd09lT1-ozl0Bz-EfEdVT234niNWZlfmJsjVGUTJTP20kr0hNeE2H-ZqlG9X/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQFS70137_qMyyclxMMeuGNa3vn7ooEXi8FB9Luma4gENrP-48GxenMfijVXsh6dkTqkGxp7MoBe2PE/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_027": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRMyCjBHnjPkaHlGxd54oJMEi_AHyReqAV8LE9qqkVQ8b8sihsk1XHxXPULeeeyKoEn5wS1kEYa2tAP/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS99xhCAGDMCDaA7u9u2qeW2VcDuS3itNaTpFGmzPBJlwBiQVRoZpmcCQJYrzeWZ8pRm4GQjHjnrokR/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_028": {
     "title": "Part 3 - Making more unselfish choices",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSp5n4Mk3AZi8HXZaVcXC8XvSW4fOL1n3uFMZHm13sVh9qwbM8dbiTJNJ7KRNlXSX6_hyQhUCMLyEz7/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRLJaBv0mhuOpLpOIQ091tuz-GF_ZQptHU2X4tLOLamfXtveuNXLYukh5L_Jfn0v_Q_hdp-LPq1dyqu/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_029": {
     "title": "Part 4 - Including others",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRjJjY8Hnox_-fG5PJeXfhinIbqNXB7zejDzJ3yen4rn-B09FUEltbh0izJC0kzNX3X0kjtkiY-eksw/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS7up-s5Ga2dXStx42fOZiNmRek7_GqRtIOqk4Oo-ZCDEQHPjTBn5HJFMUEGivXIfGWGHFozd99FwLp/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_030": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTvuuGO-elsNNbau0LFvJhPiTdRVBmGc42ufbLLPACOnN0tUMzZTnxBp02Mo-uCmjmVkZ5BWNfR3jwA/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTuqkXhT98GZ21pZUNFTetj-NBjSB4H-ql3YUbI1MWdvZjqFrdd4va_zHPdIYfBVwA5Hg8AsACgtQbA/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_031": {
     "title": "Part 1 - Accepting differences with others",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSFZBbdcDEVNJFb2eXdcWvt3BWajNWNmj5FCxmu8eTkRHpPdJpBhhBRk8FRb2B9q2ruJ8rVfXM5plGB/embed?start=false&loop=false&delayms=1800000",
     "time": "[20 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ6SaV86XPQzhdD0_-565DiWyRw2av4vO1uy_eNuhC8DWWemwhwzVKCQt_Xt-vUxYGUvyb2Iz3_eDyw/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_032": {
     "title": "(Part 1 cont.) Summary Discussion",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTxowy-UNszd0z2dN8WP8he6lrTtDtp240pEOHb0OK9RWrh7c2h3Q835xqmMihAISUG7QJed6htSNg8/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQbhSxZrrWB312LVmDp0mFrpy6dOPeQAxeqyoha79trZJPdaaRiTdfAg9sDDsiH2OImiWY-U6loax0a/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_033": {
     "title": "Part 2 - Respecting the people around us",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTSLC0Qdh1YCH5vzNIePKEk3uBLuKSiuwh9cR2gljXoI_Lu-9dcfViHxraXdUth1L2t7uQtmxBqGut_/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ0plvesEwW46Hd0ytsOXX3_KM1u6ld-h9n4A3YTZjU1vRYae683AGS-ouxhUWDxTMyjgK3nWRzrKuX/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_034": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTjBp2FPvrV1i1smlrkCzR3O7bHMOSDgz7hEV_beVEkmjrGX5dHB4pAuhydejme2MaEwRh050iFkDlA/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSOsB-oPVLjuiDg159e2JDXQgQx7El5tYa2fYRWhf732F7rbBIaoEeOHKW83mexE1SbJRcLPjMJshCU/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_035": {
     "title": "Part 3 - Showing concern for others",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSizjpdFMNmzOXPIPQ1l6DdN_gEXfbHzgJguDKHuqdNKJShF6HHbls0TDS27GtHQvewpECQpfM3kdhG/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vReXIuNSq6wk7SVd-NMtON_0ivweo8t-Q5HhmGlPm0UwUk-zBUp9L54AlL1BM8ajjEamu_uevcpcPmb/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_036": {
     "title": "Part 4 - Sharing in the responsibilities of the collective",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQh3Tt0nkKUe7QEW5OcqsaUnWaQXxm76wFXCTU131gMmdr_Dj2xeC4ROmX63bVKGWZP4NLmkWreOnho/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQyn05wQCxxwbcCAsX8gSwhDbX2_t71Mxr35tcFilSDzBB-LzNiNAQh8U0YF2MV14_QZjdApg7dvsPd/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_037": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vT5ZEKDxz-p2-lkWiT4T7VB3G4r8ikmxJchSJrQYnJyklrXpdNBRQjvqzaVh8AI7tjkksewB36BRqTj/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS5_Cx03kO18su6CcsrZhx2sxZOD7CBB2-G8mk5kAQ7YJtSP_6q7-h0lRzuYLDGKwHjPooaHHizzIId/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_038": {
     "title": "Part 1 - Seeking perfection in the products we make",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRRg7iI_KxJDUch0xgvI75xOgofALFsNtf-dhmWWbhLJOSsn2mZC7vToqq11fFM38V4Q6NoBLvoCvOr/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRvcIzFs5zXfszPQvx-d9SkIM_4KMkNdHgbyvEXyenB5-y_dGHVPBTbmQEVpX5QZkOqvaYazdunklMx/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_039": {
     "title": " Part 2 - Seeking perfection in any process or method we follow",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTVkyq_-cgeerc_mpSz0bNgLNfIPnf0D8qaiJRd6Yr_MbrZTeHvTmQQXpn3lA6px5uunh9a3m3Nyx8d/embed?start=false&loop=false&delayms=1800000",
     "time": "[20 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTJFC7j4btDJRUAdp9nxO6UD2SqT1b_ly-zo8xnGCXLwBnTHYq7PTQ-1DqEqeciKbrYUZVb0NyD6Ts1/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_040": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTbq4Yp-FguURq99Xfdaia7iqOzKyleZx8WXPnI5cpNwDl5V-roHzxCfwOed1oS29JFCecBhJSb4r2M/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTZIsrV_xUShnKpmss5pwt-hkYfAv3E1c9vPGQboF3k0IHjQIYbWT2iuhqiJ9ODzqs-ZnyaaAPhAN0K/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_041": {
     "title": "Part 3 - Seeking perfection in our interactions with others",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTUKmIbl4E4XCQQagS0NRNBD7UcqiTCH7aJEgC_QfnwyT3rFlRIarQDUUSinx617sJDi1aVCUeISdDc/embed?start=false&loop=false&delayms=1800000",
     "time": "[15 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSeKcbX5MwY69HlfCmGazXgTmk-BOAL0tiNTsAHbE3GzwqunIhvuVZ5kZyLrQ6lJQFxMze6J8SIZI4Q/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_042": {
     "title": "(Part 3 Cont.) Why should we follow 'right' behavior in the 'right' setting?",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTQlfD2F77HYb4UHOZ6Sayc4cxwtNaoCMxTegiIIjDz0jPdHOJDNy1Y_EGQtWzK60wFwCjB8VM7373S/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQO5Zmy5JDQGk9BoXiGwLXXKwQYHdU4fEowweVsHRCPDRlncDlb6iUUeAVQLHfLQhNkq7GoQyX3phpl/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_043": {
     "title": "Part 4 - Seeking perfection in the roles we have",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSDVXtdlBo7uionQJlhiSP0M5FZU2EFdK_E6o12RZI5AvVkqOQ7cDvmph54iG_FqUTyufUvtRh7_Naw/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSJ2GUnPM12Ypv2mNceCBY7sHLgSB8RbIZwDzpCduoplIJ85RPRzJYJOn11z3WRCe_RtyqbiotL6j10/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_044": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRsAscvX1Yj5-j1qOoHsNWF7z2uSJ0Z7KwZ-4_5bGzhaItVCKmn3tiaEKBdJh_e6r3X_CLy5GQWMBH1/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSp9d473c6VR_u5x6uLYyEdxvnSLx00rNYiUSjdWwqzvPVqAjqiAY_m2N0WG9PK7_YMwteH3wf6K7VT/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_045": {
     "title": "Why is it important to develop shraddha for our culture and traditions?",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSrhTQ9P5AGPUlD3HhJ-CFwaHAzL4pMeSCgNGL2-CdJW9zSt4qzPP6YIRNMDi49pmM3jwb_iCF0ikHy/embed?start=false&loop=false&delayms=1800000",
     "time": "[15 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRwo2IffKq2HxCNEM4s73t1TzhznNKEw_A1El6usKRHcOunEcOMGdGHwWirntYRbCXPX1ALD0-lbu3T/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_046": {
     "title": "Learning to respect the traditions followed by other cultures and religions",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRnXh7nzIR9rylXm6989sf696ZzTVtVrF1d8ewCWlZ-nxHq7gB6lPT-xr3abeusaO5vJjQHoGpWCd0t/embed?start=false&loop=false&delayms=1800000",
     "time": "[15 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vShDPdvxQqoZKDV4XX3JdbOwCN0yt8UkUFp6kR4ucwFmJJcc4QD8Y-PvZGXQ_ZmvEIWacoIecvY0njP/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_047": {
     "title": "Exploring the significance of our traditions - the first step in developing shraddha",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTE0_8VSHZJz3oUU9etTOUlWy1HHjzseQFAKpiP7gnb6BdckoXfUgQ4HPcUxM1KMKkwwBkAMs-ikm4w/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQ7pNIUTh_ZaCSdWXLNekQwqVqy_W4KVDn3W_i_7EuP6js5zjJ7rB0rugFoduz6X-e2_ii8te1rGFI7/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_048": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQatfz4HyxHS1rtjLBn98KkHzHZnwN1mBex_eQIkq6OMwI8n5KQpCJ1sZa2uyApFE9DuE_quDZ16aKP/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSyhw5AEdMzm57AdFg4vttpsqYzYMC3akeAqLXTNM4zQvi-_dvNnXe9E5VHf8wh5QSZLC2NbtsX_O_D/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_049": {
     "title": "Practice 1: Valuing and preserving our sovereignty and freedom",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQEgBRy7AWnFWVdpq9sj_aRoBL7aNXdXzwQZvYusRgy5lBPz-QmvvdFaDE_NpYqNh_qTVKmHwk0VQTA/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQQVPK63L771q-DmKBG36FmMSMbqBWWgvBMvZG_PBw4kp0x31Msc01o-_UciKWG5QRF9up75jrn23Dt/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_050": {
     "title": "Practice 2: Valuing and protecting our democratic traditions",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSSeoyTp8fqelATMIIuxxjgNcWJn5Y0L6hMkFdT876I2vgZ6NheikyqyZKYhKhXhRUwBlR2GX-6dxg6/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRMADRVNEf-y_Nv4KQbCxaSU-xWWn4SBlTnolbuGLOXggGLCjGcKeGd2T2VjiW0C66Igy4YCZbWLbDO/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_051": {
     "title": "Practice 3: Having shraddha in our national symbols",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vROFQUFyObpjka62T0i4uLKwpcoAG1O4YRcluJ_DWdEHx-Km4Y7l7tJQ1fEGPFq7ViO3DLSR-GqxqNP/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRHQOg5lW8GWydr5TX2gDZXskbJ0c-_NkAnPll-tnXl0Q8kLyqNgIsny-rBhp9Vf1G6oqIrnM-g_ImY/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_052": {
     "title": "Practice 4: Valuing and protecting our national monuments",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTU1eQhdSA-HZPMWn4dx1Tm43EOQ-DGYGA2xJ9YosP1qVymXQWlGcQ4RhQyKFZLJLzqPnPxD93aLAGa/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTdBnGagti0WUqlLwY1H2CCmmMG7m5UzKs3W2IrHI1ZV3QKJXxgFgaaVlHGYvrREFedaJHWhPBOLPaM/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_053": {
     "title": "Practice 5: Exercising our rights as citizens",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQuctXfLSkIfD0DrJXo_UnHJVQkvMeUXR4wbVDakaXn8JSUIsS4op04-tPRghbWalMQ2S4FpL-MvsHg/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vT1jeLTrEPH46j3mkoDMFftsVdNKGA5ooCHaBbfu_sHAd-CCIApJTR3BAKl_7_KWLuEW0_mRy58gEqL/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_054": {
     "title": "Practice 6: Having shraddha in the roles and responsibilities of citizenship",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQy37CZAvZ4DLUh5w6FrwyUrUAe0sYM7De1FEVf1yhrX3UYI3-gWTSOyXkwsz3WJpsG6yjxqG3SOucE/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vToVEh1P1SJ0EcGZfLhRXEtDmGzAu31b-Z0e0m8qEbZkVV1klZ3gyAuJlcLzaUp4nSGiU_hy5uuywIk/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_055": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTWCzofZOoWKK3aPE890Gf6Zy0S2D7pxVOVrrw8uNilpB70qMbNMAR0_hCgemPEigph_U5iQCLyD4xC/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQxdpX7GcIXu2NX8ZIYm0N5GgkGY0K5GO8oEFVVP0sr-qqzt9TNPwHq4gW6IAGwGPFsrUa91xsV6xSA/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_056": {
     "title": "Part 1 - Seeking truth using a scientific mode of thinking",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vRDmj_VnrBiQ3LAkWQu2TPgOlxSM166v9-VAOwx4c9Pumm_jW5wQ3t64fRkJ5YX3psn0Wrw4KamsDkq/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSuqhFQKkN70Swbruiq4ufHLsds2vGdXWIOIfV5QlG6K61gt9__TrkJHDt6s2klr3asSUkcwcmNTL3i/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_057": {
     "title": "Part 2 - Seeking truth in the way we react to people and situation",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQOfnGJZPFTo993WHtZ6qU4o36ye9ci0zotaIEwaiRKnUFq21mY2kHK5k4NHuaxRBYVyp3LuK4d7Fk1/embed?start=false&loop=false&delayms=1800000",
     "time": "[25 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRAo6R-WUWYB_E4dpLWLGpn3FTAuk8D3jKsNgqmqubRAGv5ojMYJQeFIA0SVHrITU5LiyxI2RtCstF7/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_058": {
     "title": "Recap",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vT8qQzWM3am-6B8LN1uovLvuIe0r3fqRkDM7YKk_PS5-nVNDv7__SOip0mEYnl63Ldebs0gRp7AbReM/embed?start=false&loop=false&delayms=1800000",
     "time": "[1 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vR9H6k2l2jTyNbCbjzC7hJNpvxXAEBcUDjq-OHe6Ff4cclEYrIE8x4OgDkNgsa8LNmVoUrr--ERlsgh/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_059": {
     "title": "Part 3 - Seeking truth in society - some methods available to us",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQhcT1PN9xxpUP1d3wHHsNp5X9l1dYKg0RpZPQxV7pYI9izwOMFPP0YPknBAYsbT7QWnMNk8ab6_LwC/embed?start=false&loop=false&delayms=1800000",
     "time": "[30 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTWRl7QzZjuGWEY8D2nkxp4knIoo8NSwYkfm3IR3DknCUPd5hsVIOqUvkNzok9U5q4c-8nBYPqVb949/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_060": {
     "title": "Part 3 - Summary",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vTs8n4VtcvpeQ9sO0oBK1-a73nnDONfehEjJ284tnGs8W0sDHoSpGHipMpE_L_gc9vRyVL1Ng3J-5Hs/embed?start=false&loop=false&delayms=1800000",
     "time": "[5 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vRyr2X9jtSvnGdbaHdiEp1UrxMZBdwUSx_Qx3eBcjsCR_pQv2Dh2G6LsjkyWHtl6jxLskUmje7MxZRh/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_061": {
     "title": "Part 4 - Seeking truth by facing the reality in oneself. i) Example of Tanka",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vSjS3mA2ADQkkpCgUZNo-mbUINJV7FGo-8bKhs_7_I4EgJhOYTzv24mRrfpeTSBwgxMMJt5135jY8R0/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vTtXtRbESLDl7M1UbcPROiqwxhi7IohvyisRwsy1KAmvNWSuOUpV_V2nplNDB-eoi41zjPB9WHKKTVs/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_062": {
     "title": "ii) Example of Brij ",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ-vPinvgm5m8KCLrO6AWsIPJ-T_u1tHDVMNnCWQjsKDkiQ41-zoBI7DYJpr6mFmgA-QGOsoXLi4J_l/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vQpp3r6QLn8XGokxg7VKu2j2wrg73UHdbVGEKLirgrYEQmNIOki8Yhv_STss94eoI67s3Vuv-yYOcnc/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_063": {
     "title": "iii) Example of Lata",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vR2LMmYA1J-1G2jWORce5zPeUSXFoHhRCLH1_oXYIy1ucs9KF-Y2ijIkumDdZ3VUDd9C8Ho1QPDFaYv/embed?start=false&loop=false&delayms=1800000",
     "time": "[10 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS-yXgJRr9Tl6H6pKH549LxfUV6YVSeI-Ygu73-kkli0jMN4AP4Yj_G6Wcg-elTlQzeqQSuTyCnTfCv/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_064": {
     "title": "Part 4 - Summary",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQjbhN_02UdF45-1yrvuZjOsgR3MWS5P5jyU2LQYMAC96siQvoufDPQbo6w9Ylzm-6-TQ_z4P5a9_9Y/embed?start=false&loop=false&delayms=1800000",
     "time": "[2 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vSdXKBn8ctNoG4m14UCuUWuk1aKHXQOq1WZ0cjJ-e-87YipcaC3Sxs8hUPzt-fsNrsUdwa8WrisRKFm/embed?start=false&loop=false&delayms=1800000"
  },
  "uo_065": {
     "title": "A case study of Swami Vivekananda",
     "type": "ppt",
     "src": "https://docs.google.com/presentation/d/e/2PACX-1vQ5iVbRJLf45M1Qahx-lypFMsyDMlgGlhDf-EaOkdNWs3DpUMRQPvpvS7yXqdVCQPoXHWB3Ogzq0MGh/embed?start=false&loop=false&delayms=1800000",
     "time": "[40 min]",
     "srcHn": "https://docs.google.com/presentation/d/e/2PACX-1vS0Zjkt2PxLq_mgLtEbCiIHhFCMy5fagVfPW1D6Hjm-La00EU9XluBV9H682yPiDPeovVLqUoROAZ-e/embed?start=false&loop=false&delayms=1800000"
  }
}