import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

import Unit from "./unit";
import ApplicationHome from "./applicationDashboard";
import ChannelDashboard from "./channelDashboard";
import StoreDashboard from "./store";
import Product from "./productPage";
import PaymentResponse from "./payments";

import { watchMySubscriptions } from '../application/user/redux_actions';

let Application = class Application extends Component {
  render() {
    watchMySubscriptions(this.props.auth.user.user.uid);
    return <Switch>
      <Route path="/application/paymentResponse/:txnid" component={PaymentResponse} />
      <Route path="/application/channel/:yearId/:channelId" component={ChannelDashboard} />
      <Route path="/application/explore/:channelId" component={Product} />
      <Route path="/application/unit/:unitId" component={Unit} />
      <Route path="/application/library" component={StoreDashboard} />
      <Route path="/application/dashboard" component={ApplicationHome} />
      <Route path="/application/" component={ApplicationHome} />
    </Switch>
  }
}

const mapStateToProps = state => ({ userState: state.userState, auth: state.auth });
export default withRouter(connect(mapStateToProps, null)(Application));