import React from "react";
// import {Link} from "react-router-dom";
// import Footer from '../../components/Footer';
import "./coc.css"
let Coc = ()=>{
    return <div className="coc" style={{maxWidth: 820, margin: "auto", padding: "3rem"}}>
      <h2 style={{lineHeight: 3}}>My code of conduct</h2> 
<ul>
<li>I understand that this Awakened Citizen Program – Digital Version, has been 
<ul>
  <li>exclusively made available to me free of cost</li>
  <li>on Illumine Digital</li>
  <li>because I am a facilitator who has been trained by Ramakrishna Mission, New Delhi</li> 
  <li>to run the Awakened Citizen Program (ACP) sessions remotely with the students of my school, that is registered with the Ramakrishna Mission, New Delhi for conducting the Awakened Citizen Program.</li>
</ul>
</li>
<li>
I agree to abide by the terms & conditions outlined below, so that the students of my school benefit & there is no unauthorized use/ misuse of the facilities and training provided to me. This includes:  
<ul>
<li>Use of the platform will be for the sole purpose of running remote classes of ACP sessions for the respective classes assigned to me by the school & not for any other purposes (either commercial or non-commercial)</li>
<li>The Unique Access Code provided to my school (that unlocks full access to the program material on Illumine Digital) is distributed and made available only to the specific teachers trained or assigned to run ACP for my school. This Access Code is not for sharing with any members of the school / parents / students, and / or any other person not directly connected with the official ACP program and its delivery.</li>
<li>As a trained teacher, I register using my true and unique credentials (name, contact number, user id & password). These credentials are non-shareable between more than one person. In case there is a change in the teacher running the program within the school, the Ramakrishna Mission, New Delhi will be informed and a new unique ID will be generated for the new teacher.</li> 
<li>I will conduct the class presentations of the ACP sessions on Illumine Digital, by showing them to remotely located students of my school, using screen-sharing facility of any video conferencing tool (such as Zoom or Skype). Any other means of conducting or sharing sessions remotely with the students will need to be checked with the RKM team before experimenting.</li> 
</ul>
</li>
<li>I understand that the usage of the ACP program digital version by every teacher/ facilitator will be tracked via the platform. In case of any sign of activity that indicates a violation of the above code of conduct on my part, will be notified to the school and may lead to revoking the access, to the digital version of ACP, of the school and all its teachers/ facilitators. 
</li>
</ul>
<h2>By signing up to this platform, I hereby agree to abide by the code of conduct and use the platform for the purpose it is meant for.</h2>

    </div>
  }
export default Coc;