import React, { Component } from "react";

let Footer = class Footer extends Component {
  render() {
    return <div
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        margin: '3rem 2rem 1rem',
        borderTop: '2px solid #dddddd',
        paddingTop: '1rem',
        fontSize: "0.75rem"
      }}>
      <div style={{ fontSize: "0.75rem", padding: "4px 8px" }}>Copyright 2018; Illumine. All Rights Reserved.</div>
      <div style={{color:"#919191", display:"flex"}}>
        <a style={{display: "flex", alignItems:"center", padding:"0px 6px"}} target="_blank" href="http://illumine.in/" rel="noopener noreferrer"><img alt="wb-icon" src="./images/assets/web-icon.svg" height={20} width="auto" /><span style={{ color: "#919191", fontSize: 12, paddingRight: 6 }}>ILLUMINE</span></a> | 
        <a style={{display: "flex", alignItems:"center", padding:"0px 6px"}} target="_blank" href="http://theawakenedcitizen.org/" rel="noopener noreferrer"><img alt="wb-icon" src="./images/assets/web-icon.svg" height={20} width="auto" /><span style={{ color: "#919191", fontSize: 12, paddingLeft: 6 }}>RKM Delhi</span></a>
      </div>
      <div >
        <div className="policies-cntr">
          <a target="_blank" href="https://illumine.in/privacy-policy.html" rel="noopener noreferrer">Privacy Policy</a>
          <a target="_blank" href="https://illumine.in/cookie-policy.html" rel="noopener noreferrer">Cookie Policy</a>
          <a target="_blank" href="https://illumine.in/terms-of-use.html" rel="noopener noreferrer">Terms &amp; Conditions</a>
        </div>
      </div>
    </div>
  }
}

export default Footer;