import React, { Component } from 'react'
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import { signOut } from "../../containers/auth/redux_actions"
import "./header.css";

let Header = class Header extends Component {
  render() {
    let active = this.props.active;
    return <div id="header-bar">
      <div className="site-logo-cntr">
        <div style={{
          height: '100%'
        }}><Link to={'/'}><img alt="illumine-awaken"
          src='./images/assets/logo-header.svg'/></Link></div>
      </div>
      <div style={{display:'flex', fontWeight:500}}>
        {/* <Link to={'/application/library'} style={{marginRight:'32px', borderBottom:"2px solid", borderColor:active==='catalouge'?'#BA1F00':'#fff'}}>Catalogue</Link> */}
        <Link to={'/application/dashboard'} style={{fontSize: 13, marginRight:'32px', borderBottom:"4px solid", paddingBottom: 16, borderColor:active==='myCapsule'?'#FFBC36':'#fff'}}>My Sessions</Link>
        <div style={{fontSize: 13, cursor:'pointer'}} onClick={()=>{this.props.signOut()}}>Logout</div>
      </div>
    </div>
  }
}

function matchDispatchToProps(dispatch) {
  return bindActionCreators({ signOut }, dispatch);
}

export default connect(
  null,matchDispatchToProps
)(Header);

