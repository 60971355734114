import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {
  IconButton,
  Input,
  InputLabel,
  InputAdornment,
  FormControl,
  Button,
  Typography, Checkbox
} from "@material-ui/core";
import { Link } from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";
import { Visibility, VisibilityOff } from "@material-ui/icons";

// import { sideNavData } from "./tileData";
// import ForgotPassword from "../../containers/ForgotPassword";
import "./Registration.css";

const styles = {};

class Registration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      password: "",
      phoneNumber: "",
      firstName: "",
      showPassword: false,
      agreement: false,
      errorMsg:null
    };

    this.handleChange = prop => event => {
      this.setState({ [prop]: event.target.value });
    };

    this.handleMouseDownPassword = event => {
      event.preventDefault();
    };

    this.handleClickShowPassword = () => {
      this.setState({ showPassword: !this.state.showPassword });
    };

    this.toggleAgreement = () => {
      this.setState({ agreement: !this.state.agreement });
    };
  }
  render() {
    const { classes } = this.props;
    const toLogin = props => <Link to="../auth/login" {...props} />;
    const toResetPassword = props => <Link to="../auth/reset-password" {...props} />;
    return (
      <div className={"auth-form-cntr"}>
        <Typography variant="title" className='box-title' align="center" gutterBottom>
          New User Registration
      </Typography>
        <form>
          <div className="loginform-cntr">
            <FormControl
              fullWidth
              className={classNames(classes.margin, "input-cntr")}
            >
              <InputLabel htmlFor="adornment-password">First Name</InputLabel>
              <Input
                id="user-first-name"
                label="firstName"
                type="text"
                autoComplete="on"
                value={this.state.firstName}
                onChange={this.handleChange("firstName")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classNames(classes.margin, "input-cntr")}
            >
              <InputLabel htmlFor="adornment-password">Email</InputLabel>
              <Input
                id="user-email"
                label="Email"
                type="text"
                autoComplete="on"
                value={this.state.email}
                onChange={this.handleChange("email")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classNames(classes.margin, "input-cntr")}
            >
              <InputLabel htmlFor="adornment-phone">Phone</InputLabel>
              <Input
                id="user-phone"
                label="Phone"
                type="tel"
                autoComplete="on"
                value={this.state.phoneNumber}
                onChange={this.handleChange("phoneNumber")}
              />
            </FormControl>
            <FormControl
              fullWidth
              className={classNames(classes.margin, "input-cntr")}
            >
              <InputLabel htmlFor="adornment-password">Password</InputLabel>
              <Input
                id="user-password"
                label="Password"
                autoComplete="off"
                type={this.state.showPassword ? "text" : "password"}
                value={this.state.password}
                onChange={this.handleChange("password")}
                endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="Toggle password visibility"
                      onClick={this.handleClickShowPassword}
                      onMouseDown={this.handleMouseDownPassword}
                    >
                      {this.state.showPassword ? (
                        <VisibilityOff />
                      ) : (
                          <Visibility />
                        )}
                    </IconButton>
                  </InputAdornment>
                }
              />
            </FormControl>
            <div style={{ display:"flex", justifyContent:"center",alignItems:"center" }}>
              <Checkbox
                checked={this.state.agreement}
                onChange={this.toggleAgreement}
              />
              <span style={{fontSize: '0.75rem' }}>I agree to the <a style={{fontSize: "inherit"}} href="./code-of-conduct">Code of Conduct</a></span></div>
            <Typography
              className="errorMsg"
              variant="caption"
              gutterBottom
              align="center"
              color="secondary"
            >
              {this.props.authMsg||this.state.errorMsg}
            </Typography>
            <FormControl
              fullWidth
              className={classNames(classes.margin, "input-cntr")}
            >
              <Button
                variant="raised"
                color="primary"
                className={classNames(classes.button, 'box-btn')}
                onClick={() => {
                  let { email, password, firstName, phoneNumber, agreement } = this.state;
                  if(email && password && firstName && phoneNumber && agreement){
                    this.props.loginAttempt({
                      email,
                      password,
                      firstName,
                      phoneNumber,
                      successCallBack: () => {
                        // console.debug("success");
                      },
                      failureCallback: () => {
                        // console.log("failed");
                      }
                    });
                  } else {
                    this.setState({errorMsg: "All Fields are compulsory"})
                  }
                }}
              >
                <span style={{ color: "#fff" }}>Register</span>
              </Button>
            </FormControl>
            <div className="login-link-cntr">
              <Button
                className={classes.button}
                component={toResetPassword}
              >
                <Typography className="errorMsg" variant="caption" gutterBottom>
                  Forgot Password?
                </Typography>
              </Button>
              <Button className={classes.button}>
                <Typography
                  className="errorMsg"
                  variant="caption"
                  gutterBottom
                  color="primary"
                  component={toLogin}
                >
                  Already a user?
                </Typography>
              </Button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

Registration.propTypes = {
  classes: PropTypes.object.isRequired,
  loginAttempt: PropTypes.func,
  authMsg: PropTypes.string
};

export default withStyles(styles)(Registration);
